//UI
export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

export const TOOGLE_BAR = "TOGGLE_BAR";
export const TOOGLE_CATEGORY = "TOOGLE_CATEGORY";
export const UPDATE_IND_TABLE = "UPDATE_IND_TABLE";
export const UPDATE_DATA_TABLE = "UPDATE_DATA_TABLE";
export const DATE_CHANGE = "DATE_CHANGE";
export const UPDATE_JENKS = "UPDATE_JENKS";
export const SET_MODE = "SET_MODE";
export const SELECT_PROJECT = "SELECT_PROJECT";
export const FETCH_PROJECT_DATA_SUCCESS = "FETCH_PROJECT_DATA_SUCCESS";
export const FETCH_PROJECT_DETAIL = "FETCH_PROJECT_DETAIL";
export const CLEAN_PROJECT = "CLEAN_PROJECT";
export const FETCH_WORK_USER_ACTION = "FETCH_WORK_USER_ACTION";
export const FETCH_USE_CASE_ACTION = "FETCH_USE_CASE_ACTION";

//INDICADORES
export const FETCH_LATEST_DATA_SUCCESS = "FETCH_LATEST_DATA_SUCCESS";
export const FETCH_BY_DATE_SUCCESS = "FETCH_BY_DATE_SUCCESS";
export const FETCH_HARD_DATA_SUCCESS = "FETCH_HARD_DATA_SUCCESS";

//RESGISTRATION
export const SIGNUPACTION = "SIGNUPACTION";
export const SIGNINACTION = "SIGNINACTION";
export const UPDATE_RESEND_TOKEN = "UPDATE_RESEND_TOKEN";
export const CHANGE_SIGN_IN_MODE = "CHANGE_SIGN_IN_MODE";

//NOTIFICATION
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

//ADMIN""
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_PROJECTS_CAT = "FETCH_PROJECTS_CAT";

//PROJECT
export const FETCH_USE_CASE_CAT = "FETCH_USE_CASE_CAT";
export const FETCH_USE_CASE = "FETCH_USE_CASE";
export const FETCH_OBJECTIVES = "FETCH_OBJECTIVES";
