import store from "../store/createStore";
import { fetchByDateSuccess } from "../actions/indicadoresActions";
import { pending, success, trriggerError } from "../actions/uiActions";
import { baseURLAPI } from "./url";
import selectCategory from "../api/selectCategory";
import {generalJenks} from "../helpers/jenksHelpers";
import {updateJenks} from "../actions/indicadoresTableActions";

export function fetchIndicadoresByDate() {
  return (dispatch) => {
    dispatch(pending());
    fetch(
      baseURLAPI +
        "/fetchIndicatorsDataByDate/" +
        store.getState().ui.fecha.getTime(),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        let jenks = generalJenks(res);
        dispatch(updateJenks(jenks));
        dispatch(fetchByDateSuccess(res));
        dispatch(selectCategory(6));
        dispatch(success());
      })
      .catch((error) => {
        dispatch(trriggerError(error));
      });
  };
}

store.subscribe(fetchIndicadoresByDate);
