import { UPDATE_DATA_TABLE } from "./actionTypes";
export function update_data_table(
  columnsTitles,
  columnFields,
  jenks,
  columnType
) {
  return {
    type: UPDATE_DATA_TABLE,
    columnsTitles: columnsTitles,
    columnFields: columnFields,
    jenks: jenks,
    columnType: columnType,
  };
}
