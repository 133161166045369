import initialState from "./initialState";
import {
  FETCH_LATEST_DATA_SUCCESS,
  FETCH_BY_DATE_SUCCESS,
  FETCH_HARD_DATA_SUCCESS,
} from "../actions/actionTypes";

export default function indicadoresReducer(
  state = initialState.indicadores,
  action
) {
  switch (action.type) {
    case FETCH_LATEST_DATA_SUCCESS: {
      return {
        ...state,
        pending: false,
        indicadores: action.payload,
      };
    }

    case FETCH_BY_DATE_SUCCESS: {
      return {
        ...state,
        pending: false,
        indicadores: action.payload,
      };
    }

    case FETCH_HARD_DATA_SUCCESS: {
      return {
        ...state,
        pending: false,
        datosDuros: action.payload,
      };
    }
    default:
      return state;
  }
}
