import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { setColor } from "../../helpers/jenksHelpers";

function IndicatorBox(props) {
  const useStyles = makeStyles({
    indexHeader: { textAlign: "center", margin: "0.6em" },
    divIndex: {
      backgroundColor: setColor(props.generalJenks, props.data),
      marginLeft: "20%",
      width: "60%",
      height: "2.5em",
      display: "table",
      marginBottom: "1em",
    },
    spanIndex: {
      fontSize: "1.3em",
      fontWeight: "bold",
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.54)",
    },
  });
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <h4 className={classes.indexHeader}>{props.header}</h4>
      <div className={classes.divIndex}>
        <span className={classes.spanIndex}>{props.data}</span>
      </div>
    </Grid>
  );
}

export default IndicatorBox;
