import {
  FETCH_USE_CASE_CAT,
  FETCH_USE_CASE,
  FETCH_OBJECTIVES,
} from "./actionTypes";

export function fetchUseCaseCatAction(useCaseCat) {
  return {
    type: FETCH_USE_CASE_CAT,
    useCaseCat: useCaseCat,
  };
}

export function fetchUseCaseAction(useCases) {
  return {
    type: FETCH_USE_CASE,
    useCases: useCases,
  };
}

export function fetchObjetivesAction(objetivesCompleted, objetivesInProgres) {
  return {
    type: FETCH_OBJECTIVES,
    objetivesCompleted: objetivesCompleted,
    objetivesInProgres: objetivesInProgres,
  };
}
