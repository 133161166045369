export const baseURL =
  "https://api.kriptotrackers.com:8443/backendService-1.2/data";

export const baseURLAPI =
  //"https://api.kriptotrackers.com:8443/backendService-1.2/api";
  "https://app.kriptotrackers.com:8443/ktsservices/api";

export const CAPTCHA_KEY = "6LfcDKoZAAAAABLdOFe2gWTV_xP4KTQ_2vdOwxLr";

//export const CAPTCHA_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
