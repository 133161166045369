import store from "../store/createStore";
import { toogle_category } from "../actions/uiActions";
import { update_data_table } from "../actions/dataTableActions";
import Geostats from "geostats";

function selectCategoryData(cat) {
  return (dispatch) => {
    let { datosDuros } = store.getState().indicadores;
    let columnsTitles = [];
    let columnFields = [];
    let jenks = [];
    let columnType = [];
    switch (cat) {
      case 1:
        columnsTitles = ["From Scratch"];
        columnFields = ["fs"];
        columnType = ["normal"];

        let arrayFS = [];
        datosDuros.map((value) => {
          arrayFS.push(value.fs);
          return null;
        });
        let statsFS = new Geostats(arrayFS);
        jenks.push(statsFS.getClassJenks(5));

        break;
      case 2:
        columnsTitles = [
          "Price (BTC)",
          "Price 1D (%)",
          "Price 7D (%)",
          //"Price change 30D (Satoshis)",
          //"Price change 90D (Satoshis)",
          "Volume (BTC)",
          "Volume 1D (%)",
          "Volume 7D (%)",
          //"Volume change 30D (BTC)",
          //"Volume change 90D (BTC)",
        ];
        columnFields = [
          "p",
          "ap1",
          "ap2",
          //"ap3",
          //"ap4",
          "v",
          "av1",
          "av2",
          //"av3",
          //"av4",
        ];

        columnType = [
          "normal",
          "change",
          "change",
          //"change",
          //"change",
          "normal",
          "change",
          "change",
          //"change",
          //"change",
        ];

        let arrayP = [];
        let arrayAP1 = [];
        let arrayAP2 = [];
        //let arrayAP3 = [];
        //let arrayAP4 = [];
        let arrayV = [];
        let arrayAV1 = [];
        let arrayAV2 = [];
        //let arrayAV3 = [];
        //let arrayAV4 = [];

        datosDuros.map((value) => {
          arrayP.push(value.p);
          arrayAP1.push(value.ap1);
          arrayAP2.push(value.ap2);
          //arrayAP3.push(value.ap3);
          //arrayAP4.push(value.ap4);
          arrayV.push(value.v);
          arrayAV1.push(value.av1);
          arrayAV2.push(value.av2);
          //arrayAV3.push(value.av3);
          //arrayAV4.push(value.av4);
          return null;
        });

        let statsP = new Geostats(arrayP);
        let statsAP1 = new Geostats(arrayAP1);
        let statsAP2 = new Geostats(arrayAP2);
        //let statsAP3 = new Geostats(arrayAP3);
        //let statsAP4 = new Geostats(arrayAP4);
        let statsV = new Geostats(arrayV);
        let statsAV1 = new Geostats(arrayAV1);
        let statsAV2 = new Geostats(arrayAV2);
        //let statsAV3 = new Geostats(arrayAV3);
        //let statsAV4 = new Geostats(arrayAV4);

        jenks.push(statsP.getClassJenks(5));
        jenks.push(statsAP1.getClassJenks(5));
        jenks.push(statsAP2.getClassJenks(5));
        //jenks.push(statsAP3.getClassJenks(5));
        //jenks.push(statsAP4.getClassJenks(5));
        jenks.push(statsV.getClassJenks(5));
        jenks.push(statsAV1.getClassJenks(5));
        jenks.push(statsAV2.getClassJenks(5));
        //jenks.push(statsAV3.getClassJenks(5));
        //jenks.push(statsAV4.getClassJenks(5));

        break;
      case 3:
        columnsTitles = [
          "Conversation (Comments)",
          "Conversation 1D (%)",
          "Conversation 7D (%)",
          "Conversation 30D (%)",
          "Conversation 90D (%)",
          "Sentiment",
          "Twitter Volume (Comments)",
        ];
        columnFields = ["vc", "avc1", "avc2", "avc3", "avc4", "s", "vt"];

        columnType = [
          "normal",
          "change",
          "change",
          "change",
          "change",
          "normal",
          "normal",
        ];

        let arrayVC = [];
        let arrayAVC1 = [];
        let arrayAVC2 = [];
        let arrayAVC3 = [];
        let arrayAVC4 = [];
        let arrayS = [];
        let arrayVT = [];

        datosDuros.map((value) => {
          arrayVC.push(value.vc);
          arrayAVC1.push(value.avc1);
          arrayAVC2.push(value.avc2);
          arrayAVC3.push(value.avc3);
          arrayAVC4.push(value.avc4);
          arrayS.push(value.s);
          arrayVT.push(value.vt);
          return null;
        });

        let statsVC = new Geostats(arrayVC);
        let statsAVC1 = new Geostats(arrayAVC1);
        let statsAVC2 = new Geostats(arrayAVC2);
        let statsAVC3 = new Geostats(arrayAVC3);
        let statsAVC4 = new Geostats(arrayAVC4);
        let statsS = new Geostats(arrayS);
        let statsVT = new Geostats(arrayVT);

        jenks.push(statsVC.getClassJenks(5));
        jenks.push(statsAVC1.getClassJenks(5));
        jenks.push(statsAVC2.getClassJenks(5));
        jenks.push(statsAVC3.getClassJenks(5));
        jenks.push(statsAVC4.getClassJenks(5));
        jenks.push(statsS.getClassJenks(5));
        jenks.push(statsVT.getClassJenks(5));

        break;
      case 4:
        columnsTitles = [
          "Developers (Contibutors)",
          "Development (Commits)",
          "Development change 7D (%)",
          "Development change 30D (%)",
          "Development change 90D (%)",
        ];
        columnFields = ["dev", "devel", "ad1", "ad1", "ad1"];

        columnType = ["normal", "normal", "change", "change", "change"];

        let arrayDEV = [];
        let arrayDEVEL = [];
        let arrayDEVEL7 = [];
        let arrayDEVEL30 = [];
        let arrayDEVEL90 = [];

        datosDuros.map((value) => {
          arrayDEV.push(value.dev);
          arrayDEVEL.push(value.devel);
          arrayDEVEL7.push(value.ad1);
          arrayDEVEL30.push(value.ad2);
          arrayDEVEL90.push(value.ad3);
          return null;
        });

        let statsDEV = new Geostats(arrayDEV);
        let statsDEVEL = new Geostats(arrayDEVEL);
        let statsDEVEL7 = new Geostats(arrayDEVEL7);
        let statsDEVEL30 = new Geostats(arrayDEVEL30);
        let statsDEVEL90 = new Geostats(arrayDEVEL90);

        jenks.push(statsDEV.getClassJenks(5));
        jenks.push(statsDEVEL.getClassJenks(5));
        jenks.push(statsDEVEL7.getClassJenks(5));
        jenks.push(statsDEVEL30.getClassJenks(5));
        jenks.push(statsDEVEL90.getClassJenks(5));

        break;
      default:
        break;
    }
    dispatch(update_data_table(columnsTitles, columnFields, jenks, columnType));
    dispatch(toogle_category(cat));
  };
}

store.subscribe(selectCategoryData);

export default selectCategoryData;
