import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Image from "react-image-webp";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import fetchSignIn from "../../api/fetchSignIn";
import resendConfirmationEmail from "../../api/resendConfirmationEmail";
import resetPassword from "../../api/resetPassword";
import { changeSignInMode } from "../../actions/uiActions";
import ReCAPTCHA from "react-google-recaptcha";
import ValidatorMessage from "../SignUp/ValidatorMessage";
import { CAPTCHA_KEY } from "../../api/url";

const customTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: { backgroundColor: "#3a77a3" },
    },
    MuiTypography: { colorPrimary: { color: "#3a77a3" } },
    MuiFormControlLabel: {
      root: { marginLeft: 0, marginRight: 0, width: "100%" },
    },
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Turing Investments Group
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://source.unsplash.com/featured/?cryptocurrency)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  message: { color: "#727C6E", fontWeight: "normal" },
  center: {
    margin: "0 auto",
  },
});

class SignInSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      remember: true,
      email: "",
      reCaptcha: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("recaptchaValidator", () => {
      const { reCaptcha } = this.state;
      if (reCaptcha) {
        return true;
      }
      return false;
    });
  }

  onRecaptchaChange = () => {
    this.setState({ reCaptcha: true });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeCheck(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  handleSubmit(event) {
    this.props.fetchSignInAction(this.state, this.props.history);
    event.preventDefault();
  }

  changeMode(signInMode) {
    this.props.changeSignInMode(signInMode);
  }

  resendConfirmationToken = () => {
    let { signInMode } = this.props.ui;
    if (signInMode === 2) {
      this.props.resendConfirmationEmail(this.state.email);
    } else if (signInMode === 3) {
      this.props.resetPassword(this.state.email, 6);
    }
  };

  render() {
    const { classes } = this.props;
    let { username, password, remember, email } = this.state;
    let { resendConfirmationToken, signInMode } = this.props.ui;
    let confirmationLink;

    let message, messageButton;
    if (signInMode === 2) {
      message =
        "Enter your email address that you used to register, We'll send you a new confirmation link";
      messageButton = "Resend confirmation link";
    } else if (signInMode === 3) {
      message =
        "Enter your email address that you used to register, We'll send you a reset password link";
      messageButton = "Reset password";
    }

    if (resendConfirmationToken) {
      confirmationLink = (
        <Grid item xs>
          <Link href="#" onClick={() => this.changeMode(2)} variant="body2">
            Resend confirmation token
          </Link>
        </Grid>
      );
    }

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Image
              className={classes.logo}
              src={require("../../images/kts_logo.png")}
              webp={require("../../images/kts_logo.webp")}
              alt="logo"
            />
            {signInMode === 1 ? (
              <ValidatorForm
                className={classes.form}
                onSubmit={this.handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      autoComplete="fusername"
                      name="username"
                      variant="outlined"
                      value={username}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      fullWidth
                      id="username"
                      label="Username"
                      autoFocus
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      value={password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ThemeProvider theme={customTheme}>
                      <ValidatorMessage
                        name="recaptcha"
                        value={this.state.reCaptcha}
                        validators={["recaptchaValidator"]}
                        errorMessages={["You are robot"]}
                        className={classes.reCaptcha}
                      >
                        <FormControlLabel
                          value={this.state.reCaptcha}
                          control={
                            <ReCAPTCHA
                              className={classes.center}
                              sitekey={CAPTCHA_KEY}
                              onChange={this.onRecaptchaChange}
                            />
                          }
                        />
                      </ValidatorMessage>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="remember"
                          checked={remember}
                          color="primary"
                          onChange={this.handleChangeCheck}
                        />
                      }
                      label="Remember me"
                    />
                    <ThemeProvider theme={customTheme}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Sign In
                      </Button>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <ThemeProvider theme={customTheme}>
                    {confirmationLink}
                    <Grid item xs>
                      <Link
                        href="#"
                        variant="body2"
                        onClick={() => this.changeMode(3)}
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </ThemeProvider>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </ValidatorForm>
            ) : (
              <div></div>
            )}
            {signInMode === 2 || signInMode === 3 ? (
              <div>
                <ValidatorForm
                  className={classes.form}
                  onSubmit={this.resendConfirmationToken}
                >
                  <h4 className={classes.message}>{message}</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        value={email}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                        fullWidth
                        autoFocus
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={customTheme}>
                        <ValidatorMessage
                          name="recaptcha"
                          value={this.state.reCaptcha}
                          validators={["recaptchaValidator"]}
                          errorMessages={["You are robot"]}
                          className={classes.reCaptcha}
                        >
                          <FormControlLabel
                            value={this.state.reCaptcha}
                            control={
                              <ReCAPTCHA
                                className={classes.center}
                                sitekey={CAPTCHA_KEY}
                                onChange={this.onRecaptchaChange}
                              />
                            }
                          />
                        </ValidatorMessage>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                  <ThemeProvider theme={customTheme}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {messageButton}
                    </Button>
                  </ThemeProvider>
                </ValidatorForm>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <ThemeProvider theme={customTheme}>
                    <Grid item>
                      <ThemeProvider theme={customTheme}>
                        <Link href="/signin" variant="body2">
                          Already have an account? Sign in
                        </Link>
                      </ThemeProvider>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return { ui: state.ui };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSignInAction: (data, history) => {
    dispatch(fetchSignIn(data, history));
  },
  resendConfirmationEmail: (email) => {
    dispatch(resendConfirmationEmail(email));
  },
  resetPassword: (email, template_id) => {
    dispatch(resetPassword(email, template_id));
  },
  changeSignInMode: (signInMode) => {
    dispatch(changeSignInMode(signInMode));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignInSide));
