import React from "react";
import { baseURLAPI } from "./url";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../actions/notificationActions";
import Button from "@material-ui/core/Button";

export default function fetchSignUp(userData, history) {
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    fetch(baseURLAPI + "/auth/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        let variant;
        res.success === true ? (variant = "success") : (variant = "error");

        enqueueSnackbar({
          message:
            res.success === true
              ? (res.message +=
                  "We have sent a confirmation mail to the provided address, please check your email")
              : res.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: variant,
            action: (key) => (
              <Button onClick={() => closeSnackbar(key)}>X</Button>
            ),
          },
        });
        if (res.success === true) {
          history.push("/signin");
        }
      })
      .catch((error) => {
        enqueueSnackbar({
          message: error.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            action: (key) => (
              <Button onClick={() => closeSnackbar(key)}>X</Button>
            ),
          },
        });
      });
  };
}
