import {
  TOOGLE_BAR,
  TOOGLE_CATEGORY,
  DATE_CHANGE,
  SET_MODE,
  SELECT_PROJECT,
  FETCH_PROJECT_DATA_SUCCESS,
  FETCH_PROJECT_DETAIL,
  PENDING,
  SUCCESS,
  ERROR,
  CLEAN_PROJECT,
  SIGNUPACTION,
  SIGNINACTION,
  UPDATE_RESEND_TOKEN,
  CHANGE_SIGN_IN_MODE,
  FETCH_WORK_USER_ACTION,
  FETCH_USE_CASE_ACTION,
} from "./actionTypes";

export function pending() {
  return {
    type: PENDING,
    pending: true,
  };
}

export function success() {
  return {
    type: SUCCESS,
    pending: false,
  };
}

export function trriggerError(log) {
  return {
    type: ERROR,
    error: true,
    log: log,
  };
}

export function toogle_bar(open) {
  return {
    type: TOOGLE_BAR,
    payload: !open,
  };
}

export function toogle_category(selectedCategory) {
  return {
    type: TOOGLE_CATEGORY,
    payload: selectedCategory,
  };
}

export function date_change(fecha) {
  return {
    type: DATE_CHANGE,
    fecha: fecha,
  };
}

export function set_mode(mode) {
  return {
    type: SET_MODE,
    mode: mode,
  };
}

export function selectProject(selectedProject) {
  return {
    type: SELECT_PROJECT,
    selectedProject: selectedProject,
  };
}

export function fetchProjectDataSuccess(selectedProjectdata, generalJenks) {
  return {
    type: FETCH_PROJECT_DATA_SUCCESS,
    selectedProjectdata: selectedProjectdata,
    generalJenks: generalJenks,
  };
}

export function fetchProjectDetailAction(data) {
  let { desempenioIndicador, desempenioData, metadata, cmcData } = data;
  return {
    type: FETCH_PROJECT_DETAIL,
    selectedProject: desempenioIndicador,
    selectedProjectdata: desempenioData,
    metadata: metadata,
    cmcData: cmcData,
  };
}

export function cleanProject() {
  return {
    type: CLEAN_PROJECT,
    projectDetail: null,
    objetivos: null,
    objetivosCumplidos: null,
    casosUso: null,
    selectedProject: null,
    selectedProjectdata: null,
    metadata: null,
    cmcData: null,
  };
}

export function signUpAction(data) {
  let { signUpRequest, signUpMessage } = data;
  return {
    type: SIGNUPACTION,
    signUpRequest: signUpRequest,
    signUpMessage: signUpMessage,
  };
}

export function singInAction(data) {
  let { succesfulLogin, user } = data;
  return {
    type: SIGNINACTION,
    succesfulLogin: succesfulLogin,
    user: user,
  };
}

export function updateResendToken(data) {
  return {
    type: UPDATE_RESEND_TOKEN,
    resendConfirmationToken: data,
  };
}

export function changeSignInMode(signInMode) {
  return {
    type: CHANGE_SIGN_IN_MODE,
    signInMode: signInMode,
  };
}

export function fetchWorkUserAction(objetivos, objetivosCumplidos) {
  return {
    type: FETCH_WORK_USER_ACTION,
    objetivos: objetivos,
    objetivosCumplidos: objetivosCumplidos,
  };
}

export function fetchUseCaseAction(casosUso) {
  return {
    type: FETCH_USE_CASE_ACTION,
    casosUso: casosUso,
  };
}
