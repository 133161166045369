import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Image from "react-image-webp";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import changePassword from "../../api/changePassword";

const customTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: { backgroundColor: "#3a77a3" },
    },
    MuiTypography: { colorPrimary: { color: "#3a77a3" } },
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Turing Investments Group
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    let { token } = this.props.match.params;

    this.state = {
      token: token,
      formData: { password: "", repeatPassword: "" },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      const { formData } = this.state;
      if (value !== formData.password) {
        return false;
      }
      return true;
    });
  }

  handleChange(event) {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    if (event.target.name === "password") {
      this.form.isFormValid(false);
    }
    this.setState({ formData });
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = {
      token: this.state.token,
      newPassword: this.state.formData.password,
    };
    this.props.changePassword(data, this.props.history);
  }

  render() {
    const { classes } = this.props;
    const { formData } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Image
            className={classes.logo}
            src={require("../../images/kts_logo.png")}
            webp={require("../../images/kts_logo.webp")}
            alt="logo"
          />
          <ValidatorForm
            className={classes.form}
            ref={(r) => (this.form = r)}
            onSubmit={this.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  value={formData.password}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  id="password"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  validators={["isPasswordMatch", "required"]}
                  errorMessages={[
                    "password mismatch",
                    "this field is required",
                  ]}
                  value={formData.repeatPassword}
                  fullWidth
                  name="repeatPassword"
                  label="Repeat Password"
                  type="password"
                  id="repeatPassword"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <ThemeProvider theme={customTheme}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Reset Password
              </Button>
            </ThemeProvider>
          </ValidatorForm>
          <ThemeProvider theme={customTheme}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  changePassword: (data, history) => {
    dispatch(changePassword(data, history));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ResetPassword));
