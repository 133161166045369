import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Image from "react-image-webp";
import { makeStyles } from "@material-ui/core/styles";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";

const useStyles = makeStyles({
  logo: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  social: { position: "relative" },
});

export default function Copyright() {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" align="center">
            {
              "This site is for general information only, procured from third party sources. We make no warranties of any kind in relation to our content,including but not limited to accuracy and updatedness. No part of the content that we provide constitutes financial advice"
            }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={4}>
          <Image
            className={classes.logo}
            src={require("../../images/kts_logo.png")}
            webp={require("../../images/kts_logo.webp")}
            alt="logo"
          />
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link
              color="inherit"
              href="https://www.turinginvestmentsgroup.com/"
            >
              {"Turing Investments Group"}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Disclaimer | Terms of Service | Privacy Policy"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="center">
            <Link
              href="https://www.facebook.com/Kriptotrackers/"
              color="inherit"
            >
              <FacebookIcon fontSize="large" />
            </Link>
            <Link href="https://twitter.com/Kriptotrackers2" color="inherit">
              <TwitterIcon fontSize="large" />
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
