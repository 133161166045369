import initialState from "./initialState";
import {
  TOOGLE_BAR,
  TOOGLE_CATEGORY,
  DATE_CHANGE,
  SET_MODE,
  SELECT_PROJECT,
  FETCH_PROJECT_DATA_SUCCESS,
  FETCH_PROJECT_DETAIL,
  PENDING,
  SUCCESS,
  ERROR,
  CLEAN_PROJECT,
  SIGNUPACTION,
  SIGNINACTION,
  UPDATE_RESEND_TOKEN,
  CHANGE_SIGN_IN_MODE,
  FETCH_WORK_USER_ACTION,
  FETCH_USE_CASE_ACTION,
} from "../actions/actionTypes";

export default function uiReducer(state = initialState.ui, action) {
  switch (action.type) {
    case TOOGLE_BAR: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case TOOGLE_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    }
    case DATE_CHANGE: {
      return {
        ...state,
        fecha: action.fecha,
      };
    }

    case SET_MODE: {
      return {
        ...state,
        mode: action.mode,
      };
    }
    case SELECT_PROJECT: {
      return {
        ...state,
        selectedProject: action.selectedProject,
      };
    }

    case FETCH_PROJECT_DATA_SUCCESS: {
      return {
        ...state,
        selectedProjectdata: action.selectedProjectdata,
        generalJenks: action.generalJenks,
      };
    }

    case FETCH_PROJECT_DETAIL: {
      let {
        projectDetail,
        selectedProject,
        selectedProjectdata,
        metadata,
        cmcData,
      } = action;
      return {
        ...state,
        projectDetail: projectDetail,
        selectedProject: selectedProject,
        selectedProjectdata: selectedProjectdata,
        metadata: metadata,
        cmcData: cmcData,
      };
    }

    case CLEAN_PROJECT: {
      let {
        objetivos,
        objetivosCumplidos,
        casosUso,
        selectedProject,
        selectedProjectdata,
        metadata,
        cmcData,
      } = action;
      return {
        ...state,
        objetivos: objetivos,
        objetivosCumplidos: objetivosCumplidos,
        casosUso: casosUso,
        selectedProject: selectedProject,
        selectedProjectdata: selectedProjectdata,
        metadata: metadata,
        cmcData: cmcData,
      };
    }

    case PENDING: {
      return {
        ...state,
        pending: action.pending,
      };
    }

    case SUCCESS: {
      return {
        ...state,
        pending: action.pending,
      };
    }

    case ERROR: {
      return {
        ...state,
        error: action.error,
        log: action.log,
      };
    }
    case SIGNUPACTION: {
      let { signUpRequest, signUpMessage } = action;
      return {
        ...state,
        signUpRequest: signUpRequest,
        signUpMessage: signUpMessage,
      };
    }
    case SIGNINACTION: {
      let { succesfulLogin, user } = action;
      return {
        ...state,
        succesfulLogin: succesfulLogin,
        user: user,
      };
    }
    case UPDATE_RESEND_TOKEN: {
      let { resendConfirmationToken } = action;
      return {
        ...state,
        resendConfirmationToken: resendConfirmationToken,
      };
    }
    case CHANGE_SIGN_IN_MODE: {
      let { signInMode } = action;
      return {
        ...state,
        signInMode: signInMode,
      };
    }
    case FETCH_WORK_USER_ACTION: {
      let { objetivos, objetivosCumplidos } = action;
      return {
        ...state,
        objetivos: objetivos,
        objetivosCumplidos: objetivosCumplidos,
      };
    }
    case FETCH_USE_CASE_ACTION: {
      let { casosUso } = action;
      return {
        ...state,
        casosUso: casosUso,
      };
    }

    default:
      return state;
  }
}
