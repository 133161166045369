import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import selectCategoryAction from "../../api/selectCategory";
import selectCategoryDataAction from "../../api/selectCategoryData";
import { date_change, set_mode } from "../../actions/uiActions";
import { fetchIndicadoresByDate } from "../../api/fetchByDate";
import { fetchHardData } from "../../api/fetchHardData";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MoneyIcon from "@material-ui/icons/Money";
import "./tools.css";
import fetchLatestDataAction from "../../api/fetchLatestData";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../actions/notificationActions";

const customContainer = createMuiTheme({
  overrides: {
    MuiGrid: {
      container: { marginTop: "0.5em", marginBottom: "0.5em" },
    },
  },
});

const pickerTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3A77A3",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#3A77A3",
      },
      current: {
        color: "#3A77A3",
      },
    },
  },
});

class Tools extends Component {
  componentDidMount = () => {
    this.props.fetchLatestData();
  };

  render() {
    const buttonTheme = createMuiTheme({
      overrides: {
        MuiButtonGroup: {
          root: {
            borderColor: "#3A77A3",
          },
          groupedOutlinedPrimary: {
            "&:hover": {
              backgroundColor: "#2D5C7D",
              color: "#FFFFFF",
            },
          },
        },
        MuiButton: {
          outlinedPrimary: {
            color: "#3A77A3",
            border: "1px solid rgba(58, 119, 163, 0.23)",
          },
          containedPrimary: {
            backgroundColor: "#2D5C7D",
          },
        },
      },
    });

    const onCLickHardData = () => {
      let { succesfulLogin } = this.props.ui;
      if (succesfulLogin !== true) {
        this.props.enqueueSnackbar({
          message: "Please Sign In to explore our awesome content",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
            action: (key) => (
              <Button onClick={() => this.props.closeSnackbar(key)}>X</Button>
            ),
          },
        });
      } else {
        this.props.set_mode(2);
        this.props.fetchHardData();
      }
    };

    const onCLickFetchIndicadores = () => {
      this.props.set_mode(1);
      this.props.fetchIndicadoresByDate();
    };

    const fetchDataByDate = (date) => {
      let { succesfulLogin } = this.props.ui;
      if (succesfulLogin !== true) {
        this.props.enqueueSnackbar({
          message: "Please Sign In to explore our awesome content",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
            action: (key) => (
              <Button onClick={() => this.props.closeSnackbar(key)}>X</Button>
            ),
          },
        });
      } else {
        this.props.date_change(date);
        if (this.props.ui.mode === 1) {
          this.props.fetchIndicadoresByDate();
        } else if (this.props.ui.mode === 2) {
          this.props.fetchHardData();
        }
      }
    };

    let ktsButton;
    if (this.props.ui.mode === 1)
      ktsButton = (
        <Button
          variant={this.props.ui.selectedCategory === 6 ? "contained" : ""}
          onClick={() => this.props.selectCategory(6)}
        >
          KTS
        </Button>
      );

    return (
      <ThemeProvider theme={customContainer}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <ThemeProvider theme={buttonTheme}>
              <ButtonGroup
                size="small"
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  variant={this.props.ui.mode === 1 ? "contained" : ""}
                  onClick={onCLickFetchIndicadores}
                >
                  <TrendingUpIcon /> Performance
                </Button>
                <Button
                  variant={this.props.ui.mode === 2 ? "contained" : ""}
                  onClick={onCLickHardData}
                >
                  <MoneyIcon /> Source Data
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <ThemeProvider theme={buttonTheme}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                size="small"
              >
                {/*<Button
                variant={
                  this.props.ui.selectedCategory === 1 ? "contained" : ""
                }
                onClick={() =>
                  this.props.ui.mode === 1
                    ? this.props.selectCategory(1)
                    : this.props.selectCategoryData(1)
                }
              >
                Innovation
              </Button>*/}
                <Button
                  variant={
                    this.props.ui.selectedCategory === 2 ? "contained" : ""
                  }
                  onClick={() =>
                    this.props.ui.mode === 1
                      ? this.props.selectCategory(2)
                      : this.props.selectCategoryData(2)
                  }
                >
                  Economic
                </Button>
                <Button
                  variant={
                    this.props.ui.selectedCategory === 3 ? "contained" : ""
                  }
                  onClick={() =>
                    this.props.ui.mode === 1
                      ? this.props.selectCategory(3)
                      : this.props.selectCategoryData(3)
                  }
                >
                  Community
                </Button>
                <Button
                  variant={
                    this.props.ui.selectedCategory === 4 ? "contained" : ""
                  }
                  onClick={() =>
                    this.props.ui.mode === 1
                      ? this.props.selectCategory(4)
                      : this.props.selectCategoryData(4)
                  }
                >
                  Development
                </Button>
                {ktsButton}
              </ButtonGroup>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={pickerTheme}>
                <KeyboardDatePicker
                  autoOk
                  disableFuture
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="date-picker-inline"
                  value={this.props.ui.fecha}
                  onChange={fetchDataByDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  selectCategory: (selectedCategory) => {
    dispatch(selectCategoryAction(selectedCategory));
  },
  selectCategoryData: (selectedCategory) => {
    dispatch(selectCategoryDataAction(selectedCategory));
  },
  date_change: (fechaInicio, fechaFinal) => {
    dispatch(date_change(fechaInicio, fechaFinal));
  },
  fetchIndicadoresByDate: () => {
    dispatch(fetchIndicadoresByDate());
  },
  set_mode: (mode) => {
    dispatch(set_mode(mode));
  },
  fetchHardData: () => {
    dispatch(fetchHardData());
  },
  fetchLatestData: () => {
    dispatch(fetchLatestDataAction());
  },
  enqueueSnackbar(...args) {
    dispatch(enqueueSnackbarAction(...args));
  },
  closeSnackbar(...args) {
    dispatch(closeSnackbarAction(...args));
  },
});

const mapStateToProps = (state) => {
  return { ui: state.ui };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
