import React, { Component } from "react";
import confirmEmail from "../../api/confirmEmail";
import { connect } from "react-redux";

class ConfirmationEmail extends Component {
  componentDidMount = () => {
    let { token } = this.props.match.params;
    this.props.confirmEmail(token, this.props.history);
  };

  render() {
    return <div></div>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmEmail: (token, history) => {
    dispatch(confirmEmail(token, history));
  },
});

export default connect(null, mapDispatchToProps)(ConfirmationEmail);
