import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

function DataCellHard(props) {
  const useStyles = makeStyles({
    root: { textAlign: "center" },
    bar: {
      height: "4px",
      position: "relative",
      overflow: "hidden",
      borderRadius: "0px",
      background: props.color,
      marginLeft: "15%",
      width: "70%",
    },
    span: {},
  });

  const classes = useStyles();

  let dataSpan;
  if (props.data || props.data === 0) {
    if (props.field === "fs") {
      props.data === 1 ? (dataSpan = "Yes") : (dataSpan = "No");
    } else if (props.field === "p") {
      dataSpan = parseFloat(props.data).toFixed(8);
    } else {
      dataSpan = parseFloat(props.data).toFixed(0);
    }
  } else {
    dataSpan = "U/A";
  }

  let arrow;
  if (props.columnType === "change") {
    if (props.data === 0) {
      arrow = <RemoveIcon style={{ color: grey[500] }} />;
    } else if (props.data > 0) {
      arrow = <ArrowUpwardIcon style={{ color: green[500] }} />;
    } else if (props.data < 0) {
      arrow = <ArrowDownwardIcon style={{ color: red[500] }} />;
    }
  }

  return (
    <div className={classes.root}>
      <span className={classes.span}>{dataSpan}</span>
      {arrow}
    </div>
  );
}
export default DataCellHard;
