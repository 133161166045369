import React from "react";
import Grid from "@material-ui/core/Grid";
import IndicatorBox from "./IndicatorBox";

export default function KtsBar(props) {
  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      spacing={0}
    >
      <IndicatorBox
        generalJenks={props.generalJenks[0]}
        header="Innovation"
        data={props.selectedProject.gi}
      />
      <IndicatorBox
        generalJenks={props.generalJenks[1]}
        header="Economic"
        data={props.selectedProject.ge}
      />
      <IndicatorBox
        generalJenks={props.generalJenks[2]}
        header="Community"
        data={props.selectedProject.gc}
      />
      <IndicatorBox
        generalJenks={props.generalJenks[3]}
        header="Development"
        data={props.selectedProject.gd}
      />
      <IndicatorBox
        generalJenks={props.generalJenks[4]}
        header="KTS Index"
        data={props.selectedProject.kts}
      />
    </Grid>
  );
}
