import { fetchLatestDataSuccess } from "../actions/indicadoresActions";
import {
  pending,
  success,
  trriggerError,
  date_change,
} from "../actions/uiActions";
import { updateJenks } from "../actions/indicadoresTableActions";
import { baseURLAPI } from "./url";
import { generalJenks } from "../helpers/jenksHelpers";

function fetchLatestData() {
  return (dispatch) => {
    dispatch(pending());
    fetch(baseURLAPI + "/latestIndicators")
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(date_change(new Date(res[0].fecha)));
        let jenks = generalJenks(res);
        dispatch(fetchLatestDataSuccess(res));
        dispatch(updateJenks(jenks));
        dispatch(success());
        return res;
      })
      .catch((error) => {
        dispatch(trriggerError(error));
      });
  };
}

export default fetchLatestData;
