import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function SentimentMeter(props) {
  const useStyles = makeStyles({
    sentimentSpan: {
      color: props.color,
      fontSize: "0.9em",
      fontWeight: "bold",
      verticalAlign: "middle",
      textAlign: "center",
    },
  });
  const classes = useStyles();
  return <span className={classes.sentimentSpan}>{props.sentiment}</span>;
}
