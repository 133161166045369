import { pending, success, trriggerError } from "../actions/uiActions";
import { baseURLAPI } from "./url";
import store from "../store/createStore";
import { fetchUseCaseCatAction } from "../actions/projectActions";

export function fetchUseCaseCat(userType) {
  return (dispatch) => {
    dispatch(pending());
    fetch(baseURLAPI + "/project/useCasesCategory", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getState().ui.user.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchUseCaseCatAction(res));
        dispatch(success());
      })
      .catch((error) => {
        dispatch(trriggerError(error));
      });
  };
}
