import {
  FETCH_LATEST_DATA_SUCCESS,
  FETCH_BY_DATE_SUCCESS,
  FETCH_HARD_DATA_SUCCESS,
} from "./actionTypes";

export function fetchLatestDataSuccess(indicadores) {
  return {
    type: FETCH_LATEST_DATA_SUCCESS,
    payload: indicadores,
  };
}

export function fetchByDateSuccess(indicadores) {
  return {
    type: FETCH_BY_DATE_SUCCESS,
    payload: indicadores,
  };
}

export function fetchHardDataSuccess(datosDuros) {
  return {
    type: FETCH_HARD_DATA_SUCCESS,
    payload: datosDuros,
  };
}
