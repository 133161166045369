import { UPDATE_JENKS, UPDATE_IND_TABLE } from "./actionTypes";
export function updateJenks(jenks) {
  return {
    type: UPDATE_JENKS,
    payload: jenks,
  };
}
export function update_ind_table(columnsTitles, columnFields, jenks) {
  return {
    type: UPDATE_IND_TABLE,
    columnsTitles: columnsTitles,
    columnFields: columnFields,
    jenks: jenks,
  };
}
