import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { createMuiTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import sendFeedback from "../../api/sendFeedback";

const customTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: { backgroundColor: "#3a77a3" },
    },
  },
});

const styles = (theme) => ({
  headerLabel: { margin: theme.spacing(2), color: "#727C6D" },
  bodyLabel: { margin: theme.spacing(2), color: "#727C6D" },
  submit: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "33%",
    marginLeft: "66%",
  },
  form: {
    width: "100%",
    margin: theme.spacing(2),
  },
  textField: {
    width: "100%",
  },
});

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.sendFeedbackAction(this.state.message);
    this.setState({ message: "" });
  }

  render() {
    const { classes } = this.props;
    let { message } = this.state;
    return (
      <Paper>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.headerLabel}
            >
              Feedback
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.bodyLabel}
            >
              We are building a great tool for the Cryptocurrency ecosystem,
              please share your thoughts about what you want to see in this kind
              of platforms, we want to hear from you
            </Typography>
          </Grid>
          <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
            <Grid item xs={12}>
              <TextValidator
                autoComplete="message"
                name="message"
                variant="outlined"
                value={message}
                validators={["required"]}
                errorMessages={["this field is required"]}
                multiline
                rows={4}
                id="message"
                label="Message"
                autoFocus
                onChange={this.handleChange}
                className={classes.textField}
              />
              <ThemeProvider theme={customTheme}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Submit
                </Button>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12}></Grid>
          </ValidatorForm>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return { ui: state.ui, indicadores: state.indicadores };
};

const mapDispatchToProps = (dispatch) => ({
  sendFeedbackAction: (message) => {
    dispatch(sendFeedback(message));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Feedback));
