import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import ProjectCell from "./ProjectCell";
import DataCell from "./DataCell";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { selectProject } from "../../actions/uiActions";
import fetchProjectData from "../../api/fetchProjectData";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { setColor } from "../../helpers/jenksHelpers";

const customRow = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: { padding: "2px" },
    },
  },
});

const styles = () => ({
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
});

class PerformanceTable extends Component {
  render() {
    const { classes } = this.props;
    const { indicadores, indicatorsTable } = this.props;
    const options = { pageSize: 20 };
    const columns = [
      {
        field: "projectid_proyecto.nombre",
        render: (rowData) => (
          <Link
            className={classes.link}
            to={"/project/" + rowData.projectid_proyecto.id_proyecto}
          >
            <ProjectCell project={rowData.projectid_proyecto} />
          </Link>
        ),
      },
    ];
    this.props.indicatorsTable.columnsTitles.map((value, index) => {
      columns.push({
        headerStyle: {
          textAlign: "center",
        },
        title: value,
        field: this.props.indicatorsTable.columnFields[index],
        render: (rowData) => (
          <DataCell
            data={rowData[this.props.indicatorsTable.columnFields[index]]}
            color={setColor(
              indicatorsTable.jenks[index],
              rowData[this.props.indicatorsTable.columnFields[index]]
            )}
            last={index === this.props.indicatorsTable.columnFields.length - 1}
          />
        ),
      });
      return null;
    });

    return (
      <ThemeProvider theme={customRow}>
        <MaterialTable
          isLoading={this.props.ui.pending}
          options={options}
          columns={columns}
          data={indicadores.indicadores}
          title={this.props.indicatorsTable.title}
        />
      </ThemeProvider>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  selectProject: (selectedProject) => {
    dispatch(selectProject(selectedProject));
  },
  fetchSelectedProjectData: (id_propyecto) => {
    dispatch(fetchProjectData(id_propyecto));
  },
});

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    indicadores: state.indicadores,
    indicatorsTable: state.indicatorsTable,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PerformanceTable));
