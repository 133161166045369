import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

var useStyles = makeStyles({
  root: { width: "100%" },
  indexHeader: {
    textAlign: "center",
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  divIndex: {
    width: "60%",
    display: "table",
  },
  spanPositive: {
    color: green[700],
    fontSize: "0.9em",
    fontWeight: "normal",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanNegative: {
    color: red[700],
    fontSize: "0.9em",
    fontWeight: "normal",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanNeutral: {
    color: grey[700],
    fontSize: "0.9em",
    fontWeight: "bold",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanLateral: {
    color: grey[700],
    fontSize: "0.9em",
    fontWeight: "bold",
    verticalAlign: "middle",
    textAlign: "center",
  },

  lateralHeader: {
    display: "inline-block",
    fontSize: "1em",
    fontWeight: "bold",
    width: "10em",
  },
});

export default function Community(props) {
  const classes = useStyles();
  let { selectedProjectdata } = props;
  let { devel, ad1, ad2, ad3, dev } = selectedProjectdata;

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Commits</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>{devel ? devel : "U/A"}</span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Commits 1D (+)</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>{ad1 ? ad1 : "U/A"}</span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Commits 7D (+)</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>{ad2 ? ad2 : "U/A"}</span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Commits 30D (+)</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>{ad3 ? ad3 : "U/A"}</span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.lateralHeader}>Developers:</span>
          <span className={classes.spanLateral}>{dev ? dev : "U/A"}</span>
        </Grid>
      </Grid>
    </div>
  );
}
