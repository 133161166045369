import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { fetchUsersByType } from "../../api/fetchUsersByType";
import { fetchProjectsCat } from "../../api/fetchProjectsCat";
import MaterialTable from "material-table";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import signUpAdmin from "../../api/SignUpAdmin";
import resetPassword from "../../api/resetPassword";
import toogleUser from "../../api/toogleUser";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../actions/notificationActions";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  table: { margin: theme.spacing(2) },
});

class NewProjectAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { seletedProject: 44 };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.props.fetchProjectsCat();
    this.props.fetchUsersByType(2);
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (email) => {
    let newUser = {
      username: email,
      email: email,
      password: this.makeId(8),
      role: ["project"],
      project_id: this.state.seletedProject,
    };
    if (this.validateEmail(newUser.email)) {
      this.props.signUpAdmin(newUser);
      setTimeout(() => {
        this.props.fetchUsersByType(2);
        this.props.resetPassword(newUser.email, 7);
      }, 1000);
    } else {
      this.props.enqueueSnackbar({
        message: "Email not valid",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
          action: (key) => (
            <Button onClick={() => this.props.closeSnackbar(key)}>X</Button>
          ),
        },
      });
    }
  };

  toogleUser = (user) => {
    this.props.toogleUser(user.id, false);
    setTimeout(() => {
      this.props.fetchUsersByType(2);
    }, 500);
  };

  makeId = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  validateEmail = (email) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  };

  render() {
    let { seletedProject } = this.state;
    const { classes, admin } = this.props;
    const { users, projectsCat } = admin;

    let items;
    if (projectsCat) {
      items = projectsCat.map((project, index) => {
        return (
          <MenuItem key={index} value={project.id_projecto}>
            {project.nombre}
          </MenuItem>
        );
      });
    }

    let columns = [
      {
        title: "Email",
        field: "email",
      },
      { title: "Username", field: "username", editable: "never" },
      {
        title: "Projecto",
        field: "projecto.nombre",
        editComponent: (props) => (
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={seletedProject}
              name="seletedProject"
              onChange={this.handleChange}
            >
              {items}
            </Select>
          </FormControl>
        ),
      },
    ];

    return (
      <Paper>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={3}
          alignItems="flex-start"
        >
          <Grid item xs>
            <div className={classes.table}>
              <MaterialTable
                title="Project Admins"
                columns={columns}
                data={users}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      this.handleSubmit(newData.email);
                      resolve();
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      const dataDelete = [...users];
                      const index = oldData.tableData.id;
                      this.toogleUser(dataDelete[index]);
                      resolve();
                    }),
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUsersByType: (userType) => {
    dispatch(fetchUsersByType(userType));
  },
  fetchProjectsCat: () => {
    dispatch(fetchProjectsCat());
  },
  signUpAdmin: (userData) => {
    dispatch(signUpAdmin(userData));
  },
  resetPassword: (email, template_id) => {
    dispatch(resetPassword(email, template_id));
  },
  toogleUser: (user_id, active) => {
    dispatch(toogleUser(user_id, active));
  },
  enqueueSnackbar: (...args) => {
    dispatch(enqueueSnackbarAction(...args));
  },
  closeSnackbar: (...args) => {
    dispatch(closeSnackbarAction(...args));
  },
});

const mapStateToProps = (state) => {
  return { ui: state.ui, admin: state.admin };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewProjectAdmin));
