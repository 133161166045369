import React from "react";
import { baseURLAPI } from "./url";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../actions/notificationActions";
import Button from "@material-ui/core/Button";
import store from "../store/createStore";

function sendFeedback(message) {
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    let { token, username } = store.getState().ui.user;

    let data = {
      username: username,
      message: message,
    };

    fetch(baseURLAPI + "/user/feedback", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        enqueueSnackbar({
          message: res.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            action: (key) => (
              <Button onClick={() => closeSnackbar(key)}>X</Button>
            ),
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar({
          message: error,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            action: (key) => (
              <Button onClick={() => closeSnackbar(key)}>X</Button>
            ),
          },
        });
      });
  };
}

store.subscribe(sendFeedback);

export default sendFeedback;
