import initialState from "./initialState";
import {
  FETCH_USE_CASE_CAT,
  FETCH_USE_CASE,
  FETCH_OBJECTIVES,
} from "../actions/actionTypes";

export default function adminReducer(state = initialState.project, action) {
  switch (action.type) {
    case FETCH_USE_CASE_CAT: {
      let { useCaseCat } = action;
      return {
        ...state,
        useCaseCat: useCaseCat,
      };
    }
    case FETCH_USE_CASE: {
      let { useCases } = action;
      return {
        ...state,
        useCases: useCases,
      };
    }
    case FETCH_OBJECTIVES: {
      let { objetivesCompleted, objetivesInProgres } = action;
      return {
        ...state,
        objetivesCompleted: objetivesCompleted,
        objetivesInProgres: objetivesInProgres,
      };
    }
    default:
      return state;
  }
}
