import React from "react";
import "./App.css";
import MainMenu from "./components/Menu/MainMenu";
import ReactGA from "react-ga";
import { Route, Switch } from "react-router-dom";
import SignInSide from "./components/SignIn/SignInSide";
import SignUp from "./components/SignUp/SignUp";
import Notifier from "./components/Notifier/Notifier";
import ConfirmationEmail from "./components/SignUp/ConfirmationEmail";
import ResetPassword from "./components/SignUp/ResetPassword";

function App() {
  ReactGA.initialize("UA-137246344-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={MainMenu} />
        <Route exact path="/passwordReset/:token" component={ResetPassword} />
        <Route
          exact
          path="/confirmEmail/:token"
          component={ConfirmationEmail}
        />
        <Route exact path="/project/:project_id" component={MainMenu} />
        <Route exact path="/feedback" component={MainMenu} />
        <Route exact path="/projectAdmin" component={MainMenu} />
        <Route exact path="/goals" component={MainMenu} />
        <Route exact path="/signin" component={SignInSide} />
        <Route exact path="/signup" component={SignUp} />
      </Switch>
      <Notifier />
    </div>
  );
}

export default App;
