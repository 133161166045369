import initialState from "./initialState";
import { UPDATE_JENKS, UPDATE_IND_TABLE } from "../actions/actionTypes";

export default function indicadoresTableReducer(
  state = initialState.indicatorsTable,
  action
) {
  switch (action.type) {
    case UPDATE_JENKS: {
      return {
        ...state,
        jenks: action.payload
      };
    }
    case UPDATE_IND_TABLE: {
      return {
        ...state,
        columnsTitles: action.columnsTitles,
        columnFields: action.columnFields,
        jenks: action.jenks
      };
    }
    default:
      return state;
  }
}
