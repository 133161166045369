import React, { Component } from "react";
import { connect } from "react-redux";
import fetchProjectDetail from "../../api/fetchProjectDetail";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import KtsBar from "../Projects/KtsBar";
import { Chip, createMuiTheme } from "@material-ui/core";
import WebIcon from "@material-ui/icons/Web";
import TwitterIcon from "@material-ui/icons/Twitter";
import ForumIcon from "@material-ui/icons/Forum";
import ExplicitIcon from "@material-ui/icons/Explicit";
import GitHubIcon from "@material-ui/icons/GitHub";
import GeneralDetail from "./GeneralDetail";
import Innovation from "../Projects/Innovation";
import { fetchWorkUsers } from "../../api/fetchWorkUser";
import { fetchUseCaseUser } from "../../api/fetchUseCasesUser";

const styles = (theme) => ({
  link: {
    display: "flex",
    color: "inherit",
    textDecoration: "inherit",
  },
  headerDiv: {
    width: "100%",
    height: "100%",
    display: "table",
    textAlign: "center",
  },
  imgDiv: {
    width: "33%",
    display: "inline-block",
    textAlign: "center",
  },
  dataHeaderDiv: {
    width: "66%",
    display: "inline-block",
    textAlign: "center",
  },
  header: {
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "2em",
  },
  icon: {
    width: 20,
    height: 20,
  },
  logo: {
    width: "5em",
    height: "5em",
  },
  tag: {
    fontSize: "1em",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(0, 0, 0, 0.54)",
  },
  priceUp: {
    marginLeft: "1em",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: "1em",
    color: green[700],
  },
  priceDown: {
    marginLeft: "1em",
    fontSize: "1em",
    verticalAlign: "middle",
    textAlign: "center",
    color: red[700],
  },
  divProperty: {
    width: "100%",
    fontSize: "0.9em",
    margin: "0.1em",
  },
  rightSpan: {
    display: "inline-block",
    paddingLeft: "17%",
    width: "55%",
  },
  rightSpanData: {
    display: "inline-block",
    width: "45%",
    color: grey[700],
    fontWeight: "bold",
  },
  linksLabelContainer: {
    width: "33%",
    height: "100%",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
  },
  linksContainer: {
    width: "66%",
    display: "inline-block",
    textAlign: "left",
  },
  rowSpacer: {
    marginTop: "1em",
  },
  lContainer: {
    width: "100%",
    margin: "5px",
  },
});

const customChip = createMuiTheme({
  overrides: {
    MuiChip: {
      root: {
        borderRadius: "none",
      },
      colorPrimary: {
        backgroundColor: "#3a77a3",
      },
    },
  },
});

class ProjectDetail extends Component {
  componentDidMount = () => {
    let { project_id } = this.props.match.params;
    let { fecha, user } = this.props.ui;
    this.props.fetchProjectDetail(project_id, fecha);

    if (user.id) {
      this.props.fetchWorkUsers(project_id, user.id);
      this.props.fetchUseCaseUser(project_id, user.id);
    } else {
      this.props.fetchWorkUsers(project_id, 0);
      this.props.fetchUseCaseUser(project_id, 0);
    }
  };

  getLinksArray = (urlSet, type) => {
    return urlSet.filter(function (url) {
      let { urlType } = url;
      let { idURLType } = urlType;
      return idURLType === type;
    });
  };

  render() {
    const { classes } = this.props;
    let {
      objetivos,
      objetivosCumplidos,
      casosUso,
      selectedProject,
      selectedProjectdata,
      metadata,
      cmcData,
    } = this.props.ui;
    let { jenks } = this.props.indicatorsTable;

    let nameHeader;
    let priceHeader;
    let ktsbar;
    let marketCapSpan;
    let volumeSpan;
    let maketPairsSpan;
    let circulatingSuplySpan;
    let totalSuplySpan;
    let maxSuplySpan;
    let webObject = [];
    let btcTalkObject = [];
    let twitterObject = [];
    let explorersObject = [];
    let codeObject = [];

    if (selectedProjectdata) {
      let { p, ap1 } = selectedProjectdata;
      priceHeader = (
        <div>
          <span className={classes.tag}>{p.toFixed(8) + " BTC  "}</span>
          <span className={ap1 > 0 ? classes.priceUp : classes.priceDown}>
            {ap1 + "% "}
          </span>
        </div>
      );
    }

    if (metadata) {
      let { logo, name, symbol } = metadata;
      nameHeader = (
        <div className={classes.headerDiv}>
          <div className={classes.imgDiv}>
            <img className={classes.logo} src={logo} alt="Project icon" />
          </div>
          <div className={classes.dataHeaderDiv}>
            <div className={classes.header}>
              {name} <span className={classes.tag}>{"(" + symbol + ")"}</span>
            </div>
            {priceHeader}
          </div>
        </div>
      );

      ktsbar = (
        <KtsBar generalJenks={jenks} selectedProject={selectedProject} />
      );

      let { urlSet } = metadata;
      webObject = this.getLinksArray(urlSet, 1);
      twitterObject = this.getLinksArray(urlSet, 3);
      btcTalkObject = this.getLinksArray(urlSet, 6);
      explorersObject = this.getLinksArray(urlSet, 8);
      codeObject = this.getLinksArray(urlSet, 9);
    }

    if (cmcData) {
      let {
        quotesSet,
        num_market_pairs,
        circulating_supply,
        total_supply,
        max_supply,
      } = cmcData;

      if (num_market_pairs)
        maketPairsSpan = (
          <Grid className={classes.divProperty} item xs={12}>
            <span className={classes.rightSpan}>Market Pairs:</span>
            <span className={classes.rightSpanData}>
              {num_market_pairs.toLocaleString()}
            </span>
          </Grid>
        );
      if (circulating_supply)
        circulatingSuplySpan = (
          <Grid className={classes.divProperty} item xs={12}>
            <span className={classes.rightSpan}>Circulating Supply:</span>
            <span className={classes.rightSpanData}>
              {circulating_supply.toLocaleString()}
            </span>
          </Grid>
        );
      if (total_supply)
        totalSuplySpan = (
          <Grid className={classes.divProperty} item xs={12}>
            <span className={classes.rightSpan}>Total Supply:</span>
            <span className={classes.rightSpanData}>
              {total_supply.toLocaleString()}
            </span>
          </Grid>
        );
      if (max_supply)
        maxSuplySpan = (
          <Grid className={classes.divProperty} item xs={12}>
            <span className={classes.rightSpan}>Max Supply:</span>
            <span className={classes.rightSpanData}>
              {max_supply.toLocaleString()}
            </span>
          </Grid>
        );

      let quoteBTC = quotesSet.filter(function (quote) {
        let { quoteType } = quote;
        let { id } = quoteType;
        return id === 1;
      });

      if (quoteBTC[0]) {
        let { market_cap, volume_24h } = quoteBTC[0];
        marketCapSpan = (
          <Grid className={classes.divProperty} item xs={12}>
            <span className={classes.rightSpan}>Market Cap:</span>
            <span className={classes.rightSpanData}>
              {market_cap.toLocaleString() + " BTC"}
            </span>
          </Grid>
        );
        volumeSpan = (
          <Grid className={classes.divProperty} item xs={12}>
            <span className={classes.rightSpan}>24 Hour Trading Vol:</span>
            <span className={classes.rightSpanData}>
              {volume_24h.toLocaleString() + " BTC"}
            </span>
          </Grid>
        );
      }
    }

    return (
      <Paper>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.rowSpacer}
            >
              <Grid item xs>
                {nameHeader}
              </Grid>
              {ktsbar}
              <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="flex-start"
              >
                {marketCapSpan}
                {volumeSpan}
                {maketPairsSpan}
                {circulatingSuplySpan}
                {maxSuplySpan}
                {totalSuplySpan}
              </Grid>
              <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="flex-start"
                className={classes.rowSpacer}
              >
                <div className={classes.lContainer}>
                  <div className={classes.linksLabelContainer}>
                    <span className={classes.spanData}>Sitio web:</span>
                  </div>
                  <div className={classes.linksContainer}>
                    <ThemeProvider theme={customChip}>
                      {webObject.map((link, index) => {
                        let { url } = link;
                        return (
                          <Chip
                            key={index}
                            color="primary"
                            size="small"
                            icon={<WebIcon />}
                            label={
                              <a
                                href={url}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.link}
                              >
                                {url}
                              </a>
                            }
                          />
                        );
                      })}
                    </ThemeProvider>
                  </div>
                </div>
                <div className={classes.lContainer}>
                  <div className={classes.linksLabelContainer}>
                    <span className={classes.spanData}>Community:</span>
                  </div>
                  <div className={classes.linksContainer}>
                    <ThemeProvider theme={customChip}>
                      {btcTalkObject.map((link, index) => {
                        let { url } = link;
                        return (
                          <Chip
                            key={index}
                            color="primary"
                            size="small"
                            icon={<ForumIcon />}
                            label={
                              <a
                                href={url}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.link}
                              >
                                Bitcointalk
                              </a>
                            }
                          />
                        );
                      })}
                    </ThemeProvider>
                    <ThemeProvider theme={customChip}>
                      {twitterObject.map((link, index) => {
                        let { url } = link;
                        return (
                          <Chip
                            key={index}
                            color="primary"
                            size="small"
                            icon={<TwitterIcon />}
                            label={
                              <a
                                href={url}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.link}
                              >
                                Twitter
                              </a>
                            }
                          />
                        );
                      })}
                    </ThemeProvider>
                  </div>
                </div>
                <div className={classes.lContainer}>
                  <div className={classes.linksLabelContainer}>
                    <span className={classes.spanData}>Explorers:</span>
                  </div>
                  <div className={classes.linksContainer}>
                    <ThemeProvider theme={customChip}>
                      {explorersObject.map((link, index) => {
                        let { url } = link;
                        return (
                          <Chip
                            key={index}
                            color="primary"
                            size="small"
                            icon={<ExplicitIcon />}
                            label={
                              <a
                                href={url}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.link}
                              >
                                {"Explorer " + (index + 1)}
                              </a>
                            }
                          />
                        );
                      })}
                    </ThemeProvider>
                  </div>
                </div>
                <div className={classes.lContainer}>
                  <div className={classes.linksLabelContainer}>
                    <span className={classes.spanData}>Code:</span>
                  </div>
                  <div className={classes.linksContainer}>
                    <ThemeProvider theme={customChip}>
                      {codeObject.map((link, index) => {
                        let { url } = link;
                        return (
                          <Chip
                            key={index}
                            color="primary"
                            size="small"
                            icon={<GitHubIcon />}
                            label={
                              <a
                                href={url}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={classes.link}
                              >
                                {"Code " + (index + 1)}
                              </a>
                            }
                          />
                        );
                      })}
                    </ThemeProvider>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <GeneralDetail
              metadata={metadata}
              selectedProjectdata={selectedProjectdata}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Innovation
            objetivos={objetivos}
            objetivosCumplidos={objetivosCumplidos}
            casosUso={casosUso}
            project_id={this.props.match.params.project_id}
          />
        </Grid>
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProjectDetail: (project_id, fecha) => {
    dispatch(fetchProjectDetail(project_id, fecha));
  },
  fetchWorkUsers: (work_id, user_id) => {
    dispatch(fetchWorkUsers(work_id, user_id));
  },
  fetchUseCaseUser: (work_id, user_id) => {
    dispatch(fetchUseCaseUser(work_id, user_id));
  },
});

const mapStateToProps = (state) => {
  return { ui: state.ui, indicatorsTable: state.indicatorsTable };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProjectDetail));
