import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import SentimentMeter from "./SentimentMeter";

var useStyles = makeStyles({
  root: { width: "100%" },
  indexHeader: {
    textAlign: "center",
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  divIndex: {
    width: "60%",
    display: "table",
  },
  spanPositive: {
    color: green[700],
    fontSize: "0.9em",
    fontWeight: "normal",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanNegative: {
    color: red[700],
    fontSize: "0.9em",
    fontWeight: "normal",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanNeutral: {
    color: grey[700],
    fontSize: "0.9em",
    fontWeight: "bold",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanLateral: {
    color: grey[700],
    fontSize: "0.9em",
    fontWeight: "bold",
    verticalAlign: "middle",
    textAlign: "center",
  },

  lateralHeader: {
    display: "inline-block",
    fontSize: "1em",
    fontWeight: "bold",
    width: "10em",
  },
});

export default function Community(props) {
  const classes = useStyles();
  let { selectedProjectdata } = props;
  let { s, vc, avc1, avc2, avc3, avc4, vt } = selectedProjectdata;

  let sentiment;
  if (s >= 0 && s < 2) {
    sentiment = <SentimentMeter sentiment="Negative" color={red[700]} />;
  } else if (s >= 2 && s < 3) {
    sentiment = <SentimentMeter sentiment="Neutral" color={grey[700]} />;
  } else {
    sentiment = <SentimentMeter sentiment="Positive" color={green[700]} />;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Comments</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>{vc ? vc : "U/A"}</span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Comments 1D</span>
          <div className={classes.divIndex}>
            <span
              className={
                avc1 >= 0 ? classes.spanPositive : classes.spanNegative
              }
            >
              {avc1 ? avc1 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Comments 7D</span>
          <div className={classes.divIndex}>
            <span
              className={
                avc2 >= 0 ? classes.spanPositive : classes.spanNegative
              }
            >
              {avc2 ? avc2 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Comments 30D</span>
          <div className={classes.divIndex}>
            <span
              className={
                avc3 >= 0 ? classes.spanPositive : classes.spanNegative
              }
            >
              {avc3 ? avc3 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Comments 90D</span>
          <div className={classes.divIndex}>
            <span
              className={
                avc4 >= 0 ? classes.spanPositive : classes.spanNegative
              }
            >
              {avc4 ? avc4 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Sentiment</span>
          <div className={classes.divIndex}>{sentiment}</div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.indexHeader}>Twitter volume</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>{vt ? vt : "U/A"}</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
