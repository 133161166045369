import Geostats from "geostats";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";

export function generalJenks(data) {
  let jenks = [];
  let arrayGI = [];
  let arrayGE = [];
  let arrayGC = [];
  let arrayGD = [];
  let arrayKTS = [];
  data.map((value) => {
    arrayGI.push(value.gi);
    arrayGE.push(value.ge);
    arrayGC.push(value.gc);
    arrayGD.push(value.gd);
    arrayKTS.push(value.kts);
    return null;
  });
  let statsGI = new Geostats(arrayGI);
  let statsGE = new Geostats(arrayGE);
  let statsGC = new Geostats(arrayGC);
  let statsGD = new Geostats(arrayGD);
  let statsKTS = new Geostats(arrayKTS);
  jenks.push(statsGI.getClassJenks(5));
  jenks.push(statsGE.getClassJenks(5));
  jenks.push(statsGC.getClassJenks(5));
  jenks.push(statsGD.getClassJenks(5));
  jenks.push(statsKTS.getClassJenks(5));

  return jenks;
}

export function setColor(jenks, data) {
  let color;
  if (data) {
    if (jenks !== undefined) {
      if (data >= jenks[0] && data < jenks[1]) {
        color = red[300];
      } else if (data >= jenks[1] && data < jenks[2]) {
        color = orange[700];
      } else if (data >= jenks[2] && data < jenks[3]) {
        color = yellow[400];
      } else if (data >= jenks[3] && data < jenks[4]) {
        color = green[300];
      } else if (data >= jenks[4] && data <= jenks[5]) {
        color = green[700];
      }
    } else {
      color = grey[700];
    }
  } else {
    color = grey[700];
  }

  return color;
}
