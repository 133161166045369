import React, { Component } from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import ProjectCell from "./ProjectCell";
import { connect } from "react-redux";
import DataCellHard from "../Tables/DataCellHard";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";
import { selectProject } from "../../actions/uiActions";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import fetchProjectData from "../../api/fetchProjectData";

const customRow = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: { padding: "2px" },
    },
  },
});

const styles = () => ({
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
});

class SourceDataTable extends Component {
  setColor(jenks, data) {
    if (data) {
      if (jenks !== undefined) {
        if (data < jenks[1]) {
          return red[300];
        } else if (data >= jenks[1] && data < jenks[2]) {
          return orange[700];
        } else if (data >= jenks[2] && data < jenks[3]) {
          return yellow[400];
        } else if (data >= jenks[3] && data < jenks[4]) {
          return green[300];
        } else if (data >= jenks[4] && data <= jenks[5]) {
          return green[700];
        }
      } else {
        return grey[700];
      }
    } else {
      return grey[700];
    }
  }

  toProjectDetail(selectedProject) {
    let selectedInd = this.props.indicadores.indicadores.filter(
      (element) =>
        element.projectid_proyecto.id_proyecto ===
        selectedProject.projectid_proyecto.id_proyecto
    );
    this.props.selectProject(selectedInd[0]);
    this.props.fetchSelectedProjectData(
      selectedProject.projectid_proyecto.id_proyecto
    );
  }

  render() {
    const { classes } = this.props;
    const { indicadores, datosDurosTable } = this.props;
    const options = { pageSize: 20 };
    const columns = [
      {
        render: (rowData) => (
          <Link
            className={classes.link}
            to={"/project/" + rowData.projectid_proyecto.id_proyecto}
          >
            <ProjectCell project={rowData.projectid_proyecto} />
          </Link>
        ),
      },
    ];
    datosDurosTable.columnsTitles.map((value, index) => {
      columns.push({
        headerStyle: {
          textAlign: "center",
        },
        title: value,
        field: datosDurosTable.columnFields[index],
        render: (rowData) => (
          <DataCellHard
            data={rowData[datosDurosTable.columnFields[index]]}
            color={this.setColor(
              datosDurosTable.jenks[index],
              rowData[datosDurosTable.columnFields[index]]
            )}
            field={datosDurosTable.columnFields[index]}
            columnType={datosDurosTable.columnType[index]}
          />
        ),
      });
      return null;
    });

    return (
      <ThemeProvider theme={customRow}>
        <MaterialTable
          isLoading={this.props.ui.pending}
          options={options}
          columns={columns}
          data={indicadores.datosDuros}
          title={this.props.datosDurosTable.title}
        />
      </ThemeProvider>
    );
  }
}

SourceDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    indicadores: state.indicadores,
    datosDurosTable: state.datosDurosTable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectProject: (selectedProject) => {
    dispatch(selectProject(selectedProject));
  },
  fetchSelectedProjectData: (id_propyecto) => {
    dispatch(fetchProjectData(id_propyecto));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SourceDataTable));
