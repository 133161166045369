import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Image from "react-image-webp";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import fetchSignUp from "../../api/fetchSignUp";
import ReCAPTCHA from "react-google-recaptcha";
import ValidatorMessage from "./ValidatorMessage";
import { CAPTCHA_KEY } from "../../api/url";

const customTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: { backgroundColor: "#3a77a3" },
    },
    MuiTypography: { colorPrimary: { color: "#3a77a3" } },
    MuiFormControlLabel: {
      root: { marginLeft: 0, marginRight: 0, width: "100%" },
    },
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Turing Investments Group
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  center: {
    margin: "0 auto",
  },
});

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "", email: "", password: "", reCaptcha: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("recaptchaValidator", () => {
      const { reCaptcha } = this.state;
      if (reCaptcha) {
        return true;
      }
      return false;
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.fetchSignUpAction(this.state, this.props.history);
  }

  onRecaptchaChange = () => {
    this.setState({ reCaptcha: true });
  };

  render() {
    const { classes } = this.props;
    let { email, username, password } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Image
            className={classes.logo}
            src={require("../../images/kts_logo.png")}
            webp={require("../../images/kts_logo.webp")}
            alt="logo"
          />
          <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  autoComplete="fusername"
                  name="username"
                  variant="outlined"
                  value={username}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  fullWidth
                  id="username"
                  label="Username"
                  autoFocus
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  value={password}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={customTheme}>
                  <ValidatorMessage
                    name="recaptcha"
                    value={this.state.reCaptcha}
                    validators={["recaptchaValidator"]}
                    errorMessages={["You are robot"]}
                  >
                    <FormControlLabel
                      value={this.state.reCaptcha}
                      control={
                        <ReCAPTCHA
                          className={classes.center}
                          sitekey={CAPTCHA_KEY}
                          onChange={this.onRecaptchaChange}
                        />
                      }
                    />
                  </ValidatorMessage>
                </ThemeProvider>
              </Grid>
            </Grid>

            <ThemeProvider theme={customTheme}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
            </ThemeProvider>
            <Grid container justify="flex-end">
              <Grid item>
                <ThemeProvider theme={customTheme}>
                  <Link href="/signin" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </ThemeProvider>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return { ui: state.ui };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSignUpAction: (data, history) => {
    dispatch(fetchSignUp(data, history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUp));
