import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function DataCell(props) {
  const useStyles = makeStyles({
    root: {
      borderRadius: "50%",
      background: props.color,
      width: "3em",
      height: "3em",
      display: "table",
      marginLeft: "30%",
    },
    rootLast: {
      background: props.color,
      marginLeft: "20%",
      width: "60%",
      height: "2.5em",
      display: "table",
    },
    bar: {
      //height: "4px",
      //position: "relative",
      //overflow: "hidden",
      borderRadius: "50%",
      background: props.color,
      //marginLeft: "15%",
      //width: "70%",
    },
    span: {
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: "center",
    },
    spanLast: {
      fontSize: "1.3em",
      fontWeight: "normal",
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: "center",
    },
  });

  const classes = useStyles();

  let dataSpan;
  if (props.data) {
    dataSpan = parseFloat(props.data).toFixed(2);
  } else {
    dataSpan = "U/A";
  }

  return (
    <div className={props.last ? classes.rootLast : classes.root}>
      <span className={props.last ? classes.spanLast : classes.span}>
        {dataSpan}
      </span>
    </div>
  );
}
export default DataCell;
