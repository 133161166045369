import store from "../store/createStore";
import moment from "moment";
import { baseURL } from "./url";
import {
  fetchProjectDataSuccess,
  pending,
  success,
  trriggerError,
} from "../actions/uiActions";
import { generalJenks } from "../helpers/jenksHelpers";

function fetchProjectData(id_propyecto) {
  return (dispatch) => {
    dispatch(pending());
    const startDate = moment(store.getState().ui.fechaInicio).format(
      "YYYY-MM-DD"
    );
    const endDate = moment(store.getState().ui.fechaFinal).format("YYYY-MM-DD");

    const formValues = {
      start_date: startDate,
      end_date: endDate,
      id_propyecto: id_propyecto,
    };
    fetch(baseURL + "/getProjectData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }

        let jenks = generalJenks(store.getState().indicadores.indicadores);
        dispatch(fetchProjectDataSuccess(res, jenks));
        dispatch(success());
      })
      .catch((error) => {
        dispatch(trriggerError(error));
      });
  };
}
store.subscribe(fetchProjectData);
export default fetchProjectData;
