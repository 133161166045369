import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { fetchUseCaseCat } from "../../api/fetchUseCaseCat";
import { fetchUseCases } from "../../api/fetchUseCases";
import { fetchObjetives } from "../../api/fetchObjetives";
import MaterialTable from "material-table";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import addUseCase from "../../api/addUseCase";
import addWork from "../../api/addWork";
import editUseCase from "../../api/editUseCase";
import editWork from "../../api/editWork";
import deleteUseCase from "../../api/deleteUseCase";
import deleteWork from "../../api/deleteWork";

const styles = (theme) => ({
  headerLabel: { margin: theme.spacing(2), color: "#727C6D" },
});

class ObjetivesAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedUseCaseCategory: 1 };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    let { user } = this.props.ui;
    if (user) {
      let { project_id } = user;
      this.props.fetchUseCaseCat();
      this.props.fetchUseCases(project_id);
      this.props.fetchObjetives(project_id);
    }
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  addNewUseCaseRequest = (data) => {
    let { project_id } = this.props.ui.user;
    let objectiveRequest = {
      project_id: project_id,
      category_id: this.state.selectedUseCaseCategory,
      title: data.title,
      description: data.descripcion,
    };

    this.props.addUseCase(objectiveRequest);
  };

  editUseCaseRequest = (newData) => {
    let objectiveRequest = {
      casoUso_id: newData.id_caso_uso,
      category_id: this.state.selectedUseCaseCategory,
      title: newData.title,
      description: newData.descripcion,
    };
    this.props.editUseCase(objectiveRequest);
  };

  deleteUseCaseRequest = (oldData) => {
    this.props.deleteUseCase(oldData.id_caso_uso);
  };

  addNewObjectiveRequest = (data, completed) => {
    let { project_id } = this.props.ui.user;
    let objetiverequest = {
      project_id: project_id,
      title: data.nombre,
      description: data.descripcion,
      source: data.fuente,
      completitionDate: completed ? data.fecha_cumplimiento.getTime() : null,
      estimatedDate: completed ? null : data.fecha_estimada.getTime(),
      completed: completed,
    };
    this.props.addWork(objetiverequest);
  };

  editObjectiveRequest = (newData, completed) => {
    if (newData.fecha_cumplimiento instanceof Date === false && completed) {
      newData.fecha_cumplimiento = new Date(newData.fecha_cumplimiento);
    }

    if (newData.fecha_estimada instanceof Date === false && !completed) {
      newData.fecha_estimada = new Date(newData.fecha_estimada);
    }

    let objectiveRequest = {
      objetivo_id: newData.id_objetivo,
      title: newData.nombre,
      description: newData.descripcion,
      source: newData.fuente,
      completitionDate: completed ? newData.fecha_cumplimiento.getTime() : null,
      estimatedDate: completed ? null : newData.fecha_estimada.getTime(),
      completed: completed,
    };
    this.props.editWork(objectiveRequest);
  };

  deleteWorkRequest = (oldData) => {
    this.props.deleteWork(oldData.id_objetivo);
  };

  render() {
    let { project } = this.props;
    let {
      useCaseCat,
      useCases,
      objetivesCompleted,
      objetivesInProgres,
    } = project;

    const { classes } = this.props;
    let { selectedUseCaseCategory } = this.state;

    let items;
    if (useCaseCat) {
      items = useCaseCat.map((category, index) => {
        return (
          <MenuItem key={index} value={category.id_category}>
            {category.category}
          </MenuItem>
        );
      });
    }

    let useCasesColumns = [
      { title: "Title", field: "title" },
      {
        title: "Description",
        field: "descripcion",
        editComponent: (props) => (
          <TextField
            id="descriptionMultiline"
            label="Description"
            multiline
            rows={4}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            variant="outlined"
          />
        ),
      },
      {
        title: "Category",
        field: "useCaseCategory.category",
        editComponent: (props) => (
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedUseCaseCategory}
              name="selectedUseCaseCategory"
              onChange={this.handleChange}
            >
              {items}
            </Select>
          </FormControl>
        ),
      },
    ];
    let completedWorkColumns = [
      { title: "Title", field: "nombre" },
      {
        title: "Descripcion",
        field: "descripcion",
        editComponent: (props) => (
          <TextField
            id="descriptionMultiline2"
            label="Descripcion"
            multiline
            rows={4}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            variant="outlined"
          />
        ),
      },
      {
        title: "Source",
        field: "fuente",
        editComponent: (props) => (
          <TextField
            id="sourceMultiline"
            label="Source"
            multiline
            rows={4}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            variant="outlined"
          />
        ),
      },
      { title: "Completition Date", field: "fecha_cumplimiento", type: "date" },
    ];

    let workInProgressColumns = [
      { title: "Title", field: "nombre" },
      {
        title: "Descripcion",
        field: "descripcion",
        editComponent: (props) => (
          <TextField
            id="descriptionWorkInProgress"
            label="Descripcion"
            multiline
            rows={4}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            variant="outlined"
          />
        ),
      },
      {
        title: "Source",
        field: "fuente",
        editComponent: (props) => (
          <TextField
            id="sourceWorkInProgress"
            label="Source"
            multiline
            rows={4}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            variant="outlined"
          />
        ),
      },
      {
        title: "Estimated completition Date",
        field: "fecha_estimada",
        type: "date",
      },
    ];

    return (
      <Paper>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.headerLabel}
            >
              Goals and Use cases
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              title="Use Cases"
              columns={useCasesColumns}
              data={useCases}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    this.addNewUseCaseRequest(newData);
                    resolve();
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    this.editUseCaseRequest(newData);
                    resolve();
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    this.deleteUseCaseRequest(oldData);
                    resolve();
                  }),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              title="Completed Work"
              columns={completedWorkColumns}
              data={objetivesCompleted}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    this.addNewObjectiveRequest(newData, true);
                    resolve();
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    this.editObjectiveRequest(newData, true);
                    resolve();
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    this.deleteWorkRequest(oldData);
                    resolve();
                  }),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              title="Work in progress"
              columns={workInProgressColumns}
              data={objetivesInProgres}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    this.addNewObjectiveRequest(newData, false);
                    resolve();
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    this.editObjectiveRequest(newData, false);
                    resolve();
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    this.deleteWorkRequest(oldData);
                    resolve();
                  }),
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return { ui: state.ui, project: state.project };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUseCaseCat: () => {
    dispatch(fetchUseCaseCat());
  },
  fetchUseCases: (project_id) => {
    dispatch(fetchUseCases(project_id));
  },
  fetchObjetives: (project_id) => {
    dispatch(fetchObjetives(project_id));
  },
  addUseCase: (data) => {
    dispatch(addUseCase(data));
  },
  editUseCase: (data) => {
    dispatch(editUseCase(data));
  },
  deleteUseCase: (useCase_id) => {
    dispatch(deleteUseCase(useCase_id));
  },
  addWork: (data) => {
    dispatch(addWork(data));
  },
  editWork: (data) => {
    dispatch(editWork(data));
  },
  deleteWork: (work_id) => {
    dispatch(deleteWork(work_id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ObjetivesAdmin));
