import initialState from "./initialState";
import { UPDATE_DATA_TABLE } from "../actions/actionTypes";

export default function datosDurosTableReducer(
  state = initialState.datosDurosTable,
  action
) {
  switch (action.type) {
    case UPDATE_DATA_TABLE: {
      return {
        ...state,
        columnsTitles: action.columnsTitles,
        columnFields: action.columnFields,
        jenks: action.jenks,
        columnType: action.columnType,
      };
    }
    default:
      return state;
  }
}
