import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "spaceBetween",
    "&:hover": {
      backgroundColor: "#727C6D",
      color: "#FFF",
    },
  },
  imgContainer: {
    marginRight: "8px",
    marginLeft: "8px",
    width: "40px",
    height: "40px",
    position: "relative",
    overflow: "hidden",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: 0,
    userSelect: "none",
    borderRadius: "50%",
    justifyContent: "center",
  },
  img: {
    textAlign: "center",
    objectFit: "cover",
    maxWidth: "100%",
  },
  span: {
    fontSize: "0.75rem",
    fontWeight: "400",
    lineHeight: "1.66",
  },
  h6: {
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.75",
  },
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
});

function ProjectCell(props) {
  const img = props.project.imageUrl;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imgContainer}>
        <img className={classes.img} src={img} alt="Project icon"></img>
      </div>
      <div>
        <span className={classes.span}>{props.project.nombre}</span>

        <br />
        <span className={classes.h6}>{props.project.tag}</span>
      </div>
    </div>
  );
}

export default ProjectCell;
