const initialState = {
  indicadores: {
    indicadores: [],
    datosDuros: [],
  },
  ui: {
    open: true,
    selectedCategory: 6,
    pending: false,
    error: null,
    log: "",
    fecha: new Date(),
    mode: 1,
    projectDetail: null,
    selectedProject: null,
    selectedProjectdata: null,
    objetivos: [],
    objetivosCumplidos: [],
    casosUso: [],
    metadata: null,
    cmcData: null,
    generalJenks: [],
    signUpRequest: null,
    signUpMessage: null,
    succesfulLogin: false,
    resendConfirmationToken: false,
    signInMode: 1,
    user: [],
  },
  notifications: { notifications: [] },
  indicatorsTable: {
    title: "Project Performance",
    columnsTitles: [
      "General Innovation",
      "General Economic",
      "General Community",
      "General Development",
      "KTS Index",
    ],
    columnFields: ["gi", "ge", "gc", "gd", "kts"],
    jenks: [],
  },
  datosDurosTable: {
    title: "Source Data",
    columnsTitles: [
      "Developers",
      "Development",
      "Devel 7D",
      "Devel 30D",
      "Devel 90D",
    ],
    columnFields: ["dev", "devel", "ad1", "ad1", "ad1"],
    jenks: [],
    columnType: [],
  },
  admin: {
    users: [],
    projectsCat: [],
  },
  project: {
    useCaseCat: [],
    useCases: [],
    objetivesCompleted: [],
    objetivesInProgres: [],
  },
};
export default initialState;
