import { toogle_category } from "../actions/uiActions";
import { update_ind_table } from "../actions/indicadoresTableActions";
import store from "../store/createStore";
import Geostats from "geostats";

function selectCategory(cat) {
  return (dispatch) => {
    let { indicadores } = store.getState().indicadores;
    let columnsTitles = [];
    let columnFields = [];
    let jenks = [];

    switch (cat) {
      case 1:
        columnsTitles = ["From Scratch", "General Innovation"];
        columnFields = ["fs", "gi"];

        let arrayFS = [];
        let arrayGIB = [];

        indicadores.map((value) => {
          arrayFS.push(value.fs);
          arrayGIB.push(value.gi);
          return null;
        });

        let statsFS = new Geostats(arrayFS);
        let statsGIB = new Geostats(arrayGIB);

        jenks.push(statsFS.getClassJenks(5));
        jenks.push(statsGIB.getClassJenks(5));

        break;
      case 2:
        columnsTitles = [
          "Price",
          "Price 1D",
          "Price 7D",
          //"Price change 30D",
          //"Price change 90D",
          "Volume",
          "Volume 1D",
          "Volume 7D",
          //"Volume change 30D",
          //"Volume change 90D",
          "General Economics",
        ];
        columnFields = [
          "p",
          "api1",
          "ap2",
          //"ap3",
          //"ap4",
          "v",
          "av1",
          "av2",
          //"av3",
          //"av4",
          "ge",
        ];

        let arrayP = [];
        let arrayAP1 = [];
        let arrayAP2 = [];
        //let arrayAP3 = [];
        //let arrayAP4 = [];
        let arrayV = [];
        let arrayAV1 = [];
        let arrayAV2 = [];
        //let arrayAV3 = [];
        //let arrayAV4 = [];
        let arrayGEB = [];

        indicadores.map((value) => {
          arrayP.push(value.p);
          arrayAP1.push(value.api1);
          arrayAP2.push(value.ap2);
          //arrayAP3.push(value.ap3);
          //arrayAP4.push(value.ap4);
          arrayV.push(value.v);
          arrayAV1.push(value.av1);
          arrayAV2.push(value.av2);
          //arrayAV3.push(value.av3);
          //arrayAV4.push(value.av4);
          arrayGEB.push(value.ge);
          return null;
        });

        let statsP = new Geostats(arrayP);
        let statsAP1 = new Geostats(arrayAP1);
        let statsAP2 = new Geostats(arrayAP2);
        //let statsAP3 = new Geostats(arrayAP3);
        //let statsAP4 = new Geostats(arrayAP4);
        let statsV = new Geostats(arrayV);
        let statsAV1 = new Geostats(arrayAV1);
        let statsAV2 = new Geostats(arrayAV2);
        //let statsAV3 = new Geostats(arrayAV3);
        //let statsAV4 = new Geostats(arrayAV4);
        let statsGEB = new Geostats(arrayGEB);

        jenks.push(statsP.getClassJenks(5));
        jenks.push(statsAP1.getClassJenks(5));
        jenks.push(statsAP2.getClassJenks(5));
        //jenks.push(statsAP3.getClassJenks(5));
        //jenks.push(statsAP4.getClassJenks(5));
        jenks.push(statsV.getClassJenks(5));
        jenks.push(statsAV1.getClassJenks(5));
        jenks.push(statsAV2.getClassJenks(5));
        //jenks.push(statsAV3.getClassJenks(5));
        //jenks.push(statsAV4.getClassJenks(5));
        jenks.push(statsGEB.getClassJenks(5));

        break;
      case 3:
        columnsTitles = [
          "Conversation",
          "Conversation 1D",
          "Conversation 7D",
          "Conversation 30D",
          "Conversation 90D",
          "Sentiment",
          "Twitter Volume",
          "General Community",
        ];
        columnFields = ["vc", "avc1", "avc2", "avc3", "avc4", "s", "vt", "gc"];

        let arrayVC = [];
        let arrayAVC1 = [];
        let arrayAVC2 = [];
        let arrayAVC3 = [];
        let arrayAVC4 = [];
        let arrayS = [];
        let arrayVT = [];
        let arrayGCB = [];

        indicadores.map((value) => {
          arrayVC.push(value.vc);
          arrayAVC1.push(value.avc1);
          arrayAVC2.push(value.avc2);
          arrayAVC3.push(value.avc3);
          arrayAVC4.push(value.avc4);
          arrayS.push(value.s);
          arrayVT.push(value.vt);
          arrayGCB.push(value.gc);
          return null;
        });

        let statsVC = new Geostats(arrayVC);
        let statsAVC1 = new Geostats(arrayAVC1);
        let statsAVC2 = new Geostats(arrayAVC2);
        let statsAVC3 = new Geostats(arrayAVC3);
        let statsAVC4 = new Geostats(arrayAVC4);
        let statsS = new Geostats(arrayS);
        let statsVT = new Geostats(arrayVT);
        let statsGCB = new Geostats(arrayGCB);

        jenks.push(statsVC.getClassJenks(5));
        jenks.push(statsAVC1.getClassJenks(5));
        jenks.push(statsAVC2.getClassJenks(5));
        jenks.push(statsAVC3.getClassJenks(5));
        jenks.push(statsAVC4.getClassJenks(5));
        jenks.push(statsS.getClassJenks(5));
        jenks.push(statsVT.getClassJenks(5));
        jenks.push(statsGCB.getClassJenks(5));

        break;
      case 4:
        columnsTitles = [
          "Developers",
          "Development",
          "Devel 7D",
          "Devel 30D",
          "Devel 90D",
          "Development General",
        ];
        columnFields = ["dev", "devel", "ad1", "ad2", "ad3", "gd"];

        let arrayDEV = [];
        let arrayDEVEL = [];
        let arrayDEVEL7 = [];
        let arrayDEVEL30 = [];
        let arrayDEVEL90 = [];
        let arrayGDB = [];

        indicadores.map((value) => {
          arrayDEV.push(value.dev);
          arrayDEVEL.push(value.devel);
          arrayDEVEL7.push(value.ad1);
          arrayDEVEL30.push(value.ad2);
          arrayDEVEL90.push(value.ad3);
          arrayGDB.push(value.gd);
          return null;
        });

        let statsDEV = new Geostats(arrayDEV);
        let statsDEVEL = new Geostats(arrayDEVEL);
        let statsDEVEL7 = new Geostats(arrayDEVEL7);
        let statsDEVEL30 = new Geostats(arrayDEVEL30);
        let statsDEVEL90 = new Geostats(arrayDEVEL90);
        let statsGDB = new Geostats(arrayGDB);

        jenks.push(statsDEV.getClassJenks(5));
        jenks.push(statsDEVEL.getClassJenks(5));
        jenks.push(statsDEVEL7.getClassJenks(5));
        jenks.push(statsDEVEL30.getClassJenks(5));
        jenks.push(statsDEVEL90.getClassJenks(5));
        jenks.push(statsGDB.getClassJenks(5));

        break;
      default:
        columnsTitles = [
          "General Innovation",
          "General Economic",
          "General Community",
          "General Development",
          "KTS Index",
        ];
        columnFields = ["gi", "ge", "gc", "gd", "kts"];

        let arrayGI = [];
        let arrayGE = [];
        let arrayGC = [];
        let arrayGD = [];
        let arrayKTS = [];

        indicadores.map((value) => {
          arrayGI.push(value.gi);
          arrayGE.push(value.ge);
          arrayGC.push(value.gc);
          arrayGD.push(value.gd);
          arrayKTS.push(value.kts);
          return null;
        });

        let statsGI = new Geostats(arrayGI);
        let statsGE = new Geostats(arrayGE);
        let statsGC = new Geostats(arrayGC);
        let statsGD = new Geostats(arrayGD);
        let statsKTS = new Geostats(arrayKTS);

        jenks.push(statsGI.getClassJenks(5));
        jenks.push(statsGE.getClassJenks(5));
        jenks.push(statsGC.getClassJenks(5));
        jenks.push(statsGD.getClassJenks(5));
        jenks.push(statsKTS.getClassJenks(5));

        break;
    }
    dispatch(update_ind_table(columnsTitles, columnFields, jenks));
    dispatch(toogle_category(cat));
  };
}

store.subscribe(selectCategory);

export default selectCategory;
