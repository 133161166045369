import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableToolbar } from "material-table";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { withStyles } from "@material-ui/core/styles";
import toogleWorkValidation from "../../api/toggleWorkValidation";
import toggleUseCaseValidation from "../../api/toggleUseCaseValidation";
import { IconButton } from "@material-ui/core";

const styles = (theme) => ({
  tableContainer: {
    marginTop: "1em",
  },
  button: {
    margin: theme.spacing(1),
    color: green[500],
    borderColor: green[500],
  },
  thumbsBlock: {
    display: "inline-block",
  },
  thumbs: { display: "inline-block" },
  thumbsSpan: { display: "block" },
});

class Innovation extends Component {
  toogleObjective = (rowData, state) => {
    let changeObjectiveRequest = {
      goal_id: rowData.id_objetivo,
      user_id: this.props.ui.user.id,
      valid: state,
    };
    this.props.toogleWorkValidation(
      changeObjectiveRequest,
      this.props.project_id
    );
    console.log(rowData);
  };

  toogleUseCase = (rowData, state) => {
    let changeUseCaseRequest = {
      goal_id: rowData.id_caso_uso,
      user_id: this.props.ui.user.id,
      valid: state,
    };

    this.props.toggleUseCaseValidation(
      changeUseCaseRequest,
      this.props.project_id
    );

    console.log(changeUseCaseRequest);
  };

  render() {
    const { classes, ui } = this.props;
    let { objetivos, objetivosCumplidos, casosUso } = this.props;
    let { user } = ui;

    let objetivosRender;
    let objetivosCumplidosRender;
    let useCasesRender;
    let displayUserColumns = false;

    if (user.roles) {
      user.roles.map((r) => {
        if (r === "ROLE_USER") displayUserColumns = true;
        return true;
      });
    }

    let objetivosColumns = [
      {
        title: "Title",
        field: "nombre",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "1em",
        },
      },
      {
        title: "Description",
        field: "descripcion",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "0.9em",
        },
      },
      {
        title: "Source",
        field: "fuente",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "0.9em",
        },
      },
      {
        title: "Estimated Completion Date",
        field: "fecha_estimada",
        type: "date",
        headerStyle: {
          textAlign: "center",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "center",
          fontSize: "0.9em",
        },
      },
      {
        title: "¿Is this a good feature?",
        headerStyle: {
          textAlign: "center",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "center",
          fontSize: "0.9em",
        },
        render: (rowData) => {
          if (displayUserColumns) {
            return (
              <div className={classes.thumbsBlock}>
                <div className={classes.thumbs}>
                  <IconButton
                    aria-label="delete"
                    color={rowData.userValidated === true ? "primary" : ""}
                    onClick={() => this.toogleObjective(rowData, true)}
                    className={
                      rowData.userValidated === true ? classes.button : ""
                    }
                  >
                    <ThumbUpAltIcon />
                  </IconButton>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesPositivas}
                  </span>
                </div>
                <div className={classes.thumbs}>
                  <IconButton
                    aria-label="delete"
                    color={rowData.userValidated === false ? "secondary" : ""}
                    onClick={() => this.toogleObjective(rowData, false)}
                  >
                    <ThumbDownAltIcon />
                  </IconButton>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesNegativas}
                  </span>
                </div>
              </div>
            );
          } else {
            return (
              <div className={classes.thumbsBlock}>
                <div className={classes.thumbs}>
                  <ThumbUpAltIcon style={{ color: green[500] }}>
                    add_circle
                  </ThumbUpAltIcon>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesPositivas}
                  </span>
                </div>
                <div className={classes.thumbs}>
                  <ThumbDownAltIcon style={{ color: red[500] }}>
                    add_circle
                  </ThumbDownAltIcon>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesNegativas}
                  </span>
                </div>
              </div>
            );
          }
        },
      },
    ];

    let obbjetivosCumplidosColumn = [
      {
        title: "Title",
        field: "nombre",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "1em",
        },
      },
      {
        title: "Description",
        field: "descripcion",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "0.9em",
        },
      },
      {
        title: "Source",
        field: "fuente",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "0.9em",
        },
      },
      {
        title: "Completion Date",
        field: "fecha_cumplimiento",
        type: "date",
        headerStyle: {
          textAlign: "center",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "center",
          fontSize: "0.9em",
        },
      },
      {
        title: "Is this fully funcional?",
        headerStyle: {
          textAlign: "center",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "center",
          fontSize: "0.9em",
        },
        render: (rowData) => {
          if (displayUserColumns) {
            return (
              <div className={classes.thumbsBlock}>
                <div className={classes.thumbs}>
                  <IconButton
                    aria-label="delete"
                    color={rowData.userValidated === true ? "primary" : ""}
                    onClick={() => this.toogleObjective(rowData, true)}
                    className={
                      rowData.userValidated === true ? classes.button : ""
                    }
                  >
                    <ThumbUpAltIcon />
                  </IconButton>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesPositivas}
                  </span>
                </div>
                <div className={classes.thumbs}>
                  <IconButton
                    aria-label="delete"
                    color={rowData.userValidated === false ? "secondary" : ""}
                    onClick={() => this.toogleObjective(rowData, false)}
                  >
                    <ThumbDownAltIcon />
                  </IconButton>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesNegativas}
                  </span>
                </div>
              </div>
            );
          } else {
            return (
              <div className={classes.thumbsBlock}>
                <div className={classes.thumbs}>
                  <ThumbUpAltIcon style={{ color: green[500] }}>
                    add_circle
                  </ThumbUpAltIcon>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesPositivas}
                  </span>
                </div>
                <div className={classes.thumbs}>
                  <ThumbDownAltIcon style={{ color: red[500] }}>
                    add_circle
                  </ThumbDownAltIcon>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesNegativas}
                  </span>
                </div>
              </div>
            );
          }
        },
      },
    ];

    let useCasesColumn = [
      {
        title: "Title",
        field: "title",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "1em",
        },
      },
      {
        title: "Description",
        field: "descripcion",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "0.9em",
        },
      },
      {
        title: "Category",
        field: "useCaseCategory.category",
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "left",
          fontSize: "0.9em",
        },
      },
      {
        title: "Is this Innovate?",
        headerStyle: {
          textAlign: "center",
        },
        cellStyle: {
          padding: "5px 5px 5px 16px",
          textAlign: "center",
          fontSize: "0.9em",
        },
        render: (rowData) => {
          if (displayUserColumns) {
            return (
              <div className={classes.thumbsBlock}>
                <div className={classes.thumbs}>
                  <IconButton
                    aria-label="delete"
                    color={rowData.userValidated === true ? "primary" : ""}
                    onClick={() => this.toogleUseCase(rowData, true)}
                    className={
                      rowData.userValidated === true ? classes.button : ""
                    }
                  >
                    <ThumbUpAltIcon />
                  </IconButton>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesPositivas}
                  </span>
                </div>
                <div className={classes.thumbs}>
                  <IconButton
                    aria-label="delete"
                    color={rowData.userValidated === false ? "secondary" : ""}
                    onClick={() => this.toogleUseCase(rowData, false)}
                  >
                    <ThumbDownAltIcon />
                  </IconButton>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesNegativas}
                  </span>
                </div>
              </div>
            );
          } else {
            return (
              <div className={classes.thumbsBlock}>
                <div className={classes.thumbs}>
                  <ThumbUpAltIcon style={{ color: green[500] }}>
                    add_circle
                  </ThumbUpAltIcon>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesPositivas}
                  </span>
                </div>
                <div className={classes.thumbs}>
                  <ThumbDownAltIcon style={{ color: red[500] }}>
                    add_circle
                  </ThumbDownAltIcon>
                  <span className={classes.thumbsSpan}>
                    {rowData.validacionesNegativas}
                  </span>
                </div>
              </div>
            );
          }
        },
      },
    ];

    if (objetivos && objetivos.length > 0) {
      objetivosRender = (
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "#727C6E",
                  color: "#FFF",
                  height: "50px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          columns={objetivosColumns}
          data={objetivos}
          title="Work in progress"
        />
      );
    }

    if (objetivosCumplidos && objetivosCumplidos.length > 0) {
      objetivosCumplidosRender = (
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "#727C6E",
                  color: "#FFF",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          columns={obbjetivosCumplidosColumn}
          data={objetivosCumplidos}
          title="Completed work"
        />
      );
    }

    if (casosUso && casosUso.length > 0) {
      useCasesRender = (
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "#727C6E",
                  color: "#FFF",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          columns={useCasesColumn}
          data={casosUso}
          title="Use cases"
        />
      );
    }

    return (
      <div>
        <Grid item className={classes.tableContainer}>
          {useCasesRender}
        </Grid>
        <Grid item className={classes.tableContainer}>
          {objetivosCumplidosRender}
        </Grid>
        <Grid item className={classes.tableContainer}>
          {objetivosRender}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ui: state.ui, admin: state.admin };
};

const mapDispatchToProps = (dispatch) => ({
  toogleWorkValidation: (data, project_id) => {
    dispatch(toogleWorkValidation(data, project_id));
  },
  toggleUseCaseValidation: (data, project_id) => {
    dispatch(toggleUseCaseValidation(data, project_id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Innovation));
