import React, { Component } from "react";
import Tools from "../Tools/Tools.js";
import PerformanceTable from "../Tables/PerformanceTable.js";
import SourceDataTable from "../Tables/SourceDataTable";
import { connect } from "react-redux";

class Projects extends Component {
  render() {
    let dataTable;
    if (this.props.ui.mode === 1) {
      dataTable = <PerformanceTable />;
    } else if (this.props.ui.mode === 2) {
      dataTable = <SourceDataTable />;
    }
    return (
      <div>
        <Tools />
        {dataTable}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { ui: state.ui };
};

export default connect(mapStateToProps)(Projects);
