import { baseURLAPI } from "./url";
import {trriggerError,fetchProjectDetailAction,pending,success,cleanProject} from "../actions/uiActions";
import {fetchIndicadoresByDate} from "./fetchByDate"

export default function  fetchProjectDetail(project_id,fecha) {
    return (dispatch) => {
        dispatch(fetchIndicadoresByDate());
        dispatch(cleanProject());
        dispatch(pending());
        fetch(baseURLAPI+"/fetchProjectDetail/"+project_id+"/"+fecha.getTime(),{
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }}).then((res) => res.json()).then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(fetchProjectDetailAction(res));
            dispatch(success());
        }).catch((error) => {
            dispatch(trriggerError(error));
        });
    }
}