import { FETCH_USERS, FETCH_PROJECTS_CAT } from "./actionTypes";

export function fetch_users(users) {
  return {
    type: FETCH_USERS,
    users: users,
  };
}

export function fecthProjectCatAction(projectsCat) {
  return {
    type: FETCH_PROJECTS_CAT,
    projectsCat: projectsCat,
  };
}
