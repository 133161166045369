import { combineReducers } from "redux";
import indicadoresReducer from "./indicadoresReducer";
import uiReducer from "./uiReducer";
import indicadoresTableReducer from "./indicatorsTableReducer";
import datosDurosTableReducer from "./datosDurosTableReducer";
import notificationReducer from "./notificationReducer";
import adminReducer from "./adminReducer";
import projectReducer from "./projectReducer";

const rootReducer = combineReducers({
  indicadores: indicadoresReducer,
  ui: uiReducer,
  indicatorsTable: indicadoresTableReducer,
  datosDurosTable: datosDurosTableReducer,
  notifications: notificationReducer,
  admin: adminReducer,
  project: projectReducer,
});

export default rootReducer;
