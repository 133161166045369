import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Link } from "react-router-dom";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppsIcon from "@material-ui/icons/Apps";
import PropTypes from "prop-types";
import Image from "react-image-webp";
import { toogle_bar } from "../../actions/uiActions";
import { LinearProgress } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Projects from "../Projects/Projects";
import Box from "@material-ui/core/Box";
import Copyright from "../Footer/Copyright";
import ProjectDetail from "../ProjectDetail/ProjectDetail";
import { singInAction } from "../../actions/uiActions";
import Button from "@material-ui/core/Button";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Feedback from "../Feedback/Feedback";
import FeedbackIcon from "@material-ui/icons/Feedback";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import NewProjectAdmin from "../ProjectAdmin/NewProjectAdmin";
import ObjetivesAdmin from "../ProjectAdmin/ObjetivesAdmin";
import ListIcon from "@material-ui/icons/List";

const drawerWidth = 220;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#3A77A3",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  logo: {
    marginTop: "5px",
  },
  container: {
    padding: theme.spacing(1),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
  loginButton: {
    position: "relative",
    marginRight: 0,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class MiniDrawer extends Component {
  state = { anchorEl: null, mobileMoreAnchorEl: null };

  componentDidMount = () => {
    let currentHideNav = window.innerWidth < 1024;
    if (currentHideNav) this.props.toogle_bar(true);

    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let data = {
        succesfulLogin: true,
        user: user,
      };
      this.props.singInAction(data);
    }
  };

  render() {
    const { classes } = this.props;
    const { ui } = this.props;
    const { user, succesfulLogin } = ui;
    const { username } = user;

    const menuId = "primary-search-account-menu";
    const mobileMenuId = "primary-search-account-menu-mobile";

    const isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleMenuClose = () => {
      this.setState({ anchorEl: null });
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    const handleMobileMenuClose = () => {
      this.setState({ mobileMoreAnchorEl: null });
    };

    const handleLogOut = () => {
      localStorage.removeItem("user");
      handleMenuClose();
      this.props.history.push("/");
      window.location.reload(false);
    };

    const progressBarTheme = createMuiTheme({
      overrides: {
        MuiLinearProgress: {
          barColorPrimary: { backgroundColor: "#75A9CE" },
        },
      },
    });

    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuOpen}>
          <p>{username}</p>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </MenuItem>
      </Menu>
    );

    let toolbarElements, feedbackElement, projectAdminsElement, goalsElement;

    if (succesfulLogin === true) {
      let { roles } = user;
      const ROLE_PROJECT = roles.find((element) => element === "ROLE_PROJECT");
      const ROLE_USER = roles.find((element) => element === "ROLE_USER");
      const ROLE_ADMIN = roles.find((element) => element === "ROLE_ADMIN");

      toolbarElements = (
        <div>
          <div className={classes.sectionDesktop}>
            <p>{username}</p>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </div>
      );
      if (ROLE_ADMIN) {
        projectAdminsElement = (
          <Link to="/projectAdmin" className={classes.link}>
            <ListItem button key="ProjectAdmin">
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="ProjectAdmin" />
            </ListItem>
          </Link>
        );
      }

      if (ROLE_PROJECT) {
        goalsElement = (
          <Link to="/goals" className={classes.link}>
            <ListItem button key="Goals">
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Goals" />
            </ListItem>
          </Link>
        );
      }

      if (ROLE_PROJECT || ROLE_USER) {
        feedbackElement = (
          <Link to="/feedback" className={classes.link}>
            <ListItem button key="Feedback">
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback" />
            </ListItem>
          </Link>
        );
      }
    } else {
      toolbarElements = (
        <Button href="/signin" color="inherit" className={classes.loginButton}>
          Login
        </Button>
      );
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.props.ui.open,
          })}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.props.toogle_bar(this.props.ui.open)}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.grow} />
            {toolbarElements}
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.props.ui.open,
            [classes.drawerClose]: !this.props.ui.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.props.ui.open,
              [classes.drawerClose]: !this.props.ui.open,
            }),
          }}
        >
          <div className={classes.toolbarIcon}>
            <Link to="/">
              <Image
                className={classes.logo}
                src={require("../../images/kts_logo.png")}
                webp={require("../../images/kts_logo.webp")}
                alt="logo"
              />
            </Link>
          </div>
          <Divider />
          <List>
            <Link to="/" className={classes.link}>
              <ListItem button key="Projects">
                <ListItemIcon>
                  <AppsIcon />
                </ListItemIcon>
                <ListItemText primary="Projects" />
              </ListItem>
            </Link>
            {goalsElement}
            {feedbackElement}
            {projectAdminsElement}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {this.props.ui.pending ? (
              <ThemeProvider theme={progressBarTheme}>
                <LinearProgress />
              </ThemeProvider>
            ) : null}
            <Switch>
              <Route exact path="/" component={Projects} />
              <Route
                exact
                path="/project/:project_id"
                component={ProjectDetail}
              />
              <Route exact path="/feedback" component={Feedback} />
              <Route exact path="/projectAdmin" component={NewProjectAdmin} />
              <Route exact path="/goals" component={ObjetivesAdmin} />
            </Switch>
            <hr />
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { ui: state.ui, indicadores: state.indicadores };
};

const mapDispatchToProps = (dispatch) => ({
  toogle_bar: (open) => {
    dispatch(toogle_bar(open));
  },
  singInAction: (data) => {
    dispatch(singInAction(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MiniDrawer));
