import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Economic from "../Projects/Economic";
import Community from "../Projects/Community";
import Development from "../Projects/Development";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const typographyTheme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
});

export default function GeneralDetail(props) {
  const classes = useStyles();
  let { selectedProjectdata, metadata } = props;
  let economicElement;
  let communityElement;
  let developmElement;

  if (selectedProjectdata) {
    economicElement = (
      <ExpansionPanelDetails>
        <Economic selectedProjectdata={selectedProjectdata} />
      </ExpansionPanelDetails>
    );
    communityElement = (
      <ExpansionPanelDetails>
        <Community selectedProjectdata={selectedProjectdata} />
      </ExpansionPanelDetails>
    );
    developmElement = (
      <ExpansionPanelDetails>
        <Development selectedProjectdata={selectedProjectdata} />
      </ExpansionPanelDetails>
    );
  }

  let descriptionElement;
  if (metadata) {
    let { description } = metadata;
    descriptionElement = (
      <ExpansionPanelDetails>
        <ThemeProvider theme={typographyTheme}>
          <Typography align="justify">{description}</Typography>
        </ThemeProvider>
      </ExpansionPanelDetails>
    );
  }
  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Description</Typography>
        </ExpansionPanelSummary>
        {descriptionElement}
      </ExpansionPanel>
      <ExpansionPanel expanded>
        <ExpansionPanelSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Economic</Typography>
        </ExpansionPanelSummary>
        {economicElement}
      </ExpansionPanel>
      <ExpansionPanel expanded>
        <ExpansionPanelSummary
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Community</Typography>
        </ExpansionPanelSummary>
        {communityElement}
      </ExpansionPanel>
      <ExpansionPanel expanded>
        <ExpansionPanelSummary
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.heading}>Development</Typography>
        </ExpansionPanelSummary>
        {developmElement}
      </ExpansionPanel>
    </div>
  );
}
