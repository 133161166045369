import React from "react";
import { baseURLAPI } from "./url";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../actions/notificationActions";
import Button from "@material-ui/core/Button";

export default function fetchSignIn(userData, history) {
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

    fetch(baseURLAPI + "/auth/signin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        if (res.success === false) {
          enqueueSnackbar({
            message: res.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning",
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>X</Button>
              ),
            },
          });
        } else {
          enqueueSnackbar({
            message: "Welcome " + res.username,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>X</Button>
              ),
            },
          });
          localStorage.setItem("user", JSON.stringify(res));
          history.push("/");
        }
      })
      .catch((error) => {
        enqueueSnackbar({
          message: "Please check your username and password",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            action: (key) => (
              <Button onClick={() => closeSnackbar(key)}>X</Button>
            ),
          },
        });
      });
  };
}
