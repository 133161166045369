import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles({
  root: { width: "100%" },
  indexHeader: {
    textAlign: "center",
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  divIndex: {
    width: "60%",
    display: "table",
  },
  spanPositive: {
    color: green[700],
    fontSize: "0.9em",
    fontWeight: "normal",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanNegative: {
    color: red[700],
    fontSize: "0.9em",
    fontWeight: "normal",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanNeutral: {
    color: grey[700],
    fontSize: "0.9em",
    fontWeight: "bold",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  spanLateral: {
    color: grey[700],
    fontSize: "0.9em",
    fontWeight: "bold",
    verticalAlign: "middle",
    textAlign: "center",
  },
  lateralHeader: {
    display: "inline-block",
    fontSize: "1em",
    fontWeight: "bold",
    width: "10em",
  },
});

export default function Economic(props) {
  const classes = useStyles();
  let { selectedProjectdata } = props;
  let { p, ap1, ap2, ap3, ap4, v, av1, av2, av3, av4 } = selectedProjectdata;

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs>
          <span className={classes.indexHeader}>Price (BTC)</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>
              {p ? p.toFixed(8) : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Price 1D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={ap1 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {ap1 ? ap1 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Price 7D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={ap2 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {ap2 ? ap2 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Price 30D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={ap3 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {ap3 ? ap3 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Price 90D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={ap4 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {ap4 ? ap4 : "U/A"}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs>
          <span className={classes.indexHeader}>Volume (BTC)</span>
          <div className={classes.divIndex}>
            <span className={classes.spanNeutral}>
              {v ? v.toLocaleString(2) : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Volume 1D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={av1 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {av1 ? av1 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Volume 7D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={av2 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {av2 ? av2 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Volume 30D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={av3 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {av3 ? av3 : "U/A"}
            </span>
          </div>
        </Grid>
        <Grid item xs>
          <span className={classes.indexHeader}>Volume 90D (%)</span>
          <div className={classes.divIndex}>
            <span
              className={av4 >= 0 ? classes.spanPositive : classes.spanNegative}
            >
              {av4 ? av4 : "U/A"}
            </span>
          </div>
        </Grid>
      </Grid>
      {/*<Grid item xs={3}>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <span className={classes.lateralHeader}>Market Cap:</span>
            <span className={classes.spanLateral}>
              {detail.cap ? detail.cap : "U/A"}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className={classes.lateralHeader}>Distance from ATH:</span>
            <span className={classes.spanLateral}>
              {detail.dmax ? detail.dmax : "U/A"}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className={classes.lateralHeader}>Distance from ATL:</span>
            <span className={classes.spanLateral}>
              {detail.dmin ? detail.dmin : "U/A"}
            </span>
          </Grid>
        </Grid>
      </Grid>*/}
    </div>
  );
}
