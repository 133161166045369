import initialState from "./initialState";
import { FETCH_USERS, FETCH_PROJECTS_CAT } from "../actions/actionTypes";

export default function adminReducer(state = initialState.admin, action) {
  switch (action.type) {
    case FETCH_USERS: {
      let { users } = action;
      return {
        ...state,
        users: users,
      };
    }
    case FETCH_PROJECTS_CAT: {
      let { projectsCat } = action;
      return {
        ...state,
        projectsCat: projectsCat,
      };
    }
    default:
      return state;
  }
}
